import { RootState } from '@/reducers'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { useEffect } from 'react'
import { socket } from '@/shared/config/socket'
import { AppDispatch } from '@/store'
import Sidebar from './Sidebar'
import { CModal, CSidebar } from '@coreui/react-pro'
import ModalAddgroup from './components/ModalAddGroup'


const token =
  localStorage.getItem('authentication_token') ||
  sessionStorage.getItem('authentication_token')

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { navigate, location } = useRouter()
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication)

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to server')
    })

    socket.on(`user_${user?.id}_new_message`, (newMsg: any) => {
      console.log('Bạn có tin nhắn mới: ', newMsg)
    })
    console.log('data', user?.id)


    return () => {
      socket.off(`user_${user?.id}_new_message`)
      socket.off(`user_${user?.id}_new_deposit`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <div className="container-web">
        {children}
      </div>
    </>
  )
}

export default Layout

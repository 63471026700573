import DotsVerticalIcon from '@/components/shared/icons/DotsVerticalIcon'
import { RootState } from '@/reducers'
import { socket } from '@/shared/config/socket'
import { IMessage } from '@/shared/model/message.model'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { AppDispatch } from '@/store'
import {
  CAvatar,
  CFormTextarea,
  CImage,
  CInputGroup,
  CInputGroupText,
  CModalBody,
    CSpinner,
} from '@coreui/react-pro'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleChatAvatar,
  handleChatcheckIsOnline,
  handleChatRoomName,
} from '../Chat'
import { selectEntityById } from '../chat.reducer'
import { getEntities } from './message.api'
import {
  fetching,
  initialMessageFilter,
  messageSelectors,
  resetFilterState,
  setFilterState,
  toggleMessageInfo,
} from './message.reducer'
import MessageInfo from './MessageInfo'
import { handleUploadDocument, handleUploadImage } from '@/shared/utils/ultils'
import avatar1 from '@/assets/img/images.png'
import { BiSolidSend } from 'react-icons/bi'
import ModalInfo from '../../components/ModalInfo'
import TranslateComponent from '@/shared/utils/googleTranslate'
import {
  BsArrowLeftShort,
  BsFileEarmarkArrowDownFill,
  BsReply,
  BsX,
  BsXLg,
} from 'react-icons/bs'
import TranslateComponentHTML from '@/shared/utils/googleTranslateHtml'
import { GrAttachment } from 'react-icons/gr'
import {
  AiFillCloseCircle,
  AiOutlineFile,
  AiOutlinePicture,
  AiOutlinePlayCircle,
  AiOutlinePushpin,
  AiOutlineUnorderedList,
} from 'react-icons/ai'
import { IChat } from '@/shared/model/chat.model'
import { IUser } from '@/shared/model/user.model'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import axios from '@/shared/config/axios-interceptor'
import { FiArrowLeft } from 'react-icons/fi'
import SModal from '@/components/shared/Modal/SModal'

export enum MessageType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  LINK = 'LINK',
  FILE = 'FILE',
}

type MessageRef = HTMLDivElement | null

const Message = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { navigate } = useRouter()
  const { params } = useRouter()
  const { roomId } = params
  const { user } = useSelector((state: RootState) => state.authentication)
  const { initialState } = useSelector(
    (state: RootState) => state.messageReducer,
  )

  const [ showVideo, setShowVideo ] = useState<any>();
  const { messageInfoShow, totalPages, filterState, loading } = initialState
  const chatRoom = useSelector(selectEntityById(roomId || ''))
  const listMessage = useSelector(messageSelectors.selectAll)

  const listMessageRef = useRef<HTMLDivElement>(null)
  const [messages, setMessages] = useState<IMessage[]>([])
  const [messageInput, setMessageInput] = useState('')
  const [reply, setReply] = useState<IMessage | null>(null)
  const [searchUserGroup, setSearchUserGroup] = useState(false)
  const [pin, setPin] = useState<any>()
  const [showListPin, setShowListPin] = useState(false)

  const messageRefs = useRef<Record<string, MessageRef>>({})

  const scrollToMessage = (id: string) => {
    const targetElement = messageRefs.current[id]
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' })
      targetElement.classList.add('highlight')

      setTimeout(() => {
        targetElement.classList.remove('highlight')
      }, 1000) // Adjust the duration to fit your needs
    }
  }

  const toggle = () => {
    dispatch(toggleMessageInfo(!messageInfoShow))
  }

  const isSender = (senderId: number) => senderId === Number(user?.id)

  const isSenderHasNextMessage = (msg: IMessage, index: number) =>
    index > 0 && messages[index - 1].senderId === msg.senderId

  const isSenderHasPreviousMsg = (msg: IMessage, index: number) =>
    index + 1 < messages.length && messages[index + 1].senderId === msg.senderId

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && messageInput.trim()) {
      sendMessage()
    }
  }

  const scrollToBottom = () => {
    const timeout = setTimeout(() => {
      // Scroll to the bottom of the messages div
      if (listMessageRef.current) {
        listMessageRef.current.scrollTop = listMessageRef.current.scrollHeight
      }
    }, 700)

    return () => clearTimeout(timeout)
  }

  let pattern = /https?:\/\/[^\s]+|www\.[^\s]+|localhost|[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}(?:\/[^\s]*)?/g

  const sendMessage = () => {
    if (!messageInput.trim()) return
    let type = MessageType.TEXT
    if (pattern.test(messageInput)) {
      type = MessageType.LINK
    }
    const messageData = {
      id: chatRoom?.id,
      content: messageInput,
      type,
      parentId: reply?._id ? reply?._id : null,
    }
    socket.emit('send_message', messageData)
    setReply(null)
  }

  const pinMessage = (id: string, type: number = 1) => {
    const messageData = {
      messageId: id,
      isPin: type === 0 ? 0 : 1,
      id: roomId,
    }
    socket.emit('pin_message', messageData)
  }

  const seenMessage = async (roomId: number) => {
    try {
      const a = await axios.post(`/user/seen-notify`, {
        roomId,
        userId: Number(user?.id),
      })
      if (a) {
        dispatch(getEntities(filterState))
      }
    } catch (err) {}
  }

  const getPin = async (roomId: number) => {
    try {
      const a = await axios.get(`/message/pin/${roomId}`)
      setPin(a?.data)
    } catch (err) {}
  }

  useEffect(() => {
    if (roomId) {
      getPin(+roomId)
    }
  }, [roomId])

  const removeMessage = (messageId: string) => {
    const messageData = {
      id: roomId,
      messageId,
    }
    socket.emit('remove_message', messageData)
  }

  const handleScroll = () => {
    const div = listMessageRef.current

    if (div?.scrollTop === 0) {
      console.log('Đã đến đầu list message')
      if (totalPages > 1)
        dispatch(
          setFilterState({ ...filterState, limit: filterState.limit + 50 }),
        )
    }
  }

  useEffect(() => {
    const div = listMessageRef.current
    if (Number(roomId)) {
      dispatch(
        setFilterState({ ...initialMessageFilter, roomId: Number(roomId) }),
      )
    }
    scrollToBottom()
    setReply(null)

    return () => {
      dispatch(resetFilterState())
      div?.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId])

  useEffect(() => {
    if (filterState.roomId) {
      dispatch(fetching())
      dispatch(getEntities(filterState))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState)])

  useEffect(() => {
    setMessages([...listMessage].reverse())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(listMessage)])

  useEffect(() => {
    const handleMessage = (newMsg: IMessage) => {
      if (Number(roomId) === newMsg.roomId) {
        if(Number(user?.id ?? 0) === Number(newMsg.senderId)){
          setMessageInput('')
        }
        setMessages([...messages, newMsg])
        scrollToBottom()
      }
    }

    socket.on(`user_${user?.id}_new_message`, handleMessage)

    const handleMessage1 = (data: any) => {
      const newMessage = messages.filter((msg) => msg._id !== data)
      setMessages(newMessage)
    }
    socket.on(`user_${user?.id}_remove_message`, handleMessage1)

    const handleMessage2 = (data: any) => {
      if (data.isPin === 1) {
        setPin(data)
      } else {
        if (roomId) {
          getPin(+roomId)
        }
      }
    }
    socket.on(`user_${user?.id}_pin_message`, handleMessage2)

    return () => {
      socket.off(`user_${user?.id}_new_message`, handleMessage)
      socket.off(`user_${user?.id}_remove_message`, handleMessage1)
      socket.off(`user_${user?.id}_pin_message`, handleMessage2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, totalPages, roomId])

  useEffect(() => {
    const div = listMessageRef.current
    div?.addEventListener('scroll', handleScroll)

    return () => {
      div?.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), totalPages])

  const fileInput = useRef<any>(null)
  const videoInput = useRef<any>(null)
  const documentInput = useRef<any>(null)

  const uploadFile = async (file: File) => {
    try {
      return await handleUploadImage(file)
    } catch (e) {
      console.error('Error uploading file', e)
    }
  }

  const uploadDocument = async (file: File) => {
    try {
      return await handleUploadDocument(file)
    } catch (e) {
      console.error('Error uploading file', e)
    }
  }

  const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      const messageData = {
        id: roomId,
        content: await uploadFile(file),
        type: MessageType.IMAGE,
      }
      socket.emit('send_message', messageData)
      if (fileInput.current) {
        fileInput.current.value = ''
      }
    }
  }

  const onChangeVideo = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      const messageData = {
        id: roomId,
        content: await uploadFile(file),
        type: MessageType.VIDEO,
      }
      socket.emit('send_message', messageData)
      if (videoInput.current) {
        videoInput.current.value = ''
      }
    }
  }

  const onChangeDocument = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      const messageData = {
        id: roomId,
        content: await uploadDocument(file),
        type: MessageType.FILE,
      }

      socket.emit('send_message', messageData)
      if (documentInput.current) {
        documentInput.current.value = ''
      }
    }
  }

  const handleReply = (item: IMessage) => {
    console.log(item)
    setReply(item)
  }

  const handleCloseReply = () => {
    setReply(null)
  }

  if (!roomId || !chatRoom)
    return (
      <div className="d-flex flex-column justify-content-center background-message align-items-center h-100"></div>
    )

  const renderContent = (msg: IMessage) => {
    if (msg.type === MessageType.VIDEO) {
      return (
        <div className='content-video-text' onClick={() => setShowVideo(msg.content)}>
          <AiOutlinePlayCircle size={35} />
          <video className='w-100' src={msg.content}></video>
        </div>
      )
    }


    if (msg.type === MessageType.IMAGE) {
      return (
        <PhotoView src={msg?.content}>
          <CImage width={'100%'} height={'auto'} src={msg?.content} />
        </PhotoView>
      )
    }

    if (msg.type === MessageType.LINK) {
      return (
        <TranslateComponentHTML
          stringText={msg.content.replace(
            pattern,
            '<a target="_blank" href="$&">$&</a>',
          )}
        />
      )
    }

    if (msg.type === MessageType.TEXT) {
      return <TranslateComponentHTML stringText={msg.content} />
    }

    if (msg.type === MessageType.FILE) {
      if (msg?.content) {
        const urlObject = new URL(msg?.content)
        const pathname = urlObject.pathname
        const basename = pathname.substring(pathname.lastIndexOf('/') + 1)
        return (
          <a
            className="d-flex gap-2 text-decoration-none"
            target="_blank"
            href={msg?.content}
          >
            <div
              className="d-flex align-items-center justify-content-center rounded-circle"
              style={{ width: 40, height: 40, background: '#5FBE67' }}
            >
              <BsFileEarmarkArrowDownFill color="#fff" />
            </div>

            <div style={{ color: '#000', fontSize: 13, fontWeight: 600 }}>
              {basename}
            </div>
          </a>
        )
      }

      return msg.content
    }

    return <TranslateComponentHTML stringText={msg.content} />
  }

  const listPin = () => {
    dispatch(setFilterState({ ...filterState, isPin: 1 }))
    setShowListPin(true)
  }

  const showAll = () => {
    dispatch(setFilterState({ ...filterState, isPin: 0 }))
    setShowListPin(false)
  }

  const renderReply = (msg: IMessage) => {
    if (!msg.parent) {
      return null
    }

    const render = () => {
      if (msg?.parent?.type == MessageType.IMAGE) {
        return 'Photo...'
      }

      if (msg?.parent?.type == MessageType.FILE) {
        return 'File...'
      }

      return msg?.parent?.content
    }

    return (
      <>
        <div
          onClick={() => scrollToMessage(msg?.parent?._id ?? '')}
          className="mb-2 cursor-pointer"
          style={{
            background: 'rgb(87 74 173)',
            color: '#fff',
            padding: 6,
            borderRadius: 6,
            fontSize: 13,
          }}
        >
          <div style={{ color: '#fff', fontWeight: 600 }}>
            {msg?.parent?.sender?.username}
          </div>
          <div className="text-truncate-custom text-truncate-1">{render()}</div>
        </div>
      </>
    )
  }

  const renderContentReply = (reply: IMessage) => {
    if (reply.type == MessageType.IMAGE) {
      return 'Photo...'
    }

    if (reply.type == MessageType.FILE) {
      return 'File...'
    }

    return reply.content
  }

  const renderUserInGroup = (room: IChat) => {
    if (room?.isGroup === 0) return <></>

    const pattern = /@(\w*)$/
    const match = messageInput.match(pattern)
    const filter = match ? match[1] : ''

    // const users = room?.users.filter((item) => `@${item.username}`.includes(messageInput) ) ?? []

    const users =
      room?.users.filter((item) =>
        item?.username?.toLowerCase().includes(filter.toLowerCase()),
      ) ?? []

    if (users.length <= 0) return <></>

    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          width: 270,
          maxHeight: 300,
          overflowY: 'auto',
          background: '#1f2020',
          marginBottom: '10px',
          borderRadius: 8,
        }}
      >
        <div className="d-flex flex-column align-items-center w-100 py-2 list-user-group">
          {users?.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => selectUser(item)}
                className="item w-100 px-3 d-flex align-items-center py-2 gap-3 cursor-pointer"
                style={{ borderRadius: 4 }}
              >
                <CAvatar
                  src={reply?.sender?.avatar || avatar1}
                  className="avatar-32"
                  style={{ flexGrow: 0, flexShrink: 0 }}
                />
                <div
                  className="text-truncate-custom text-truncate-1"
                  style={{ fontSize: 12, color: '#fff', whiteSpace: 'nowrap' }}
                >
                  {item?.fullName ?? item?.username}{' '}
                  <span style={{ color: 'rgb(170,170,170)', marginLeft: 10 }}>
                    @{item?.username}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const selectUser = (item: IUser) => {
    const regex = /@/
    let array = messageInput.split(' ')
    const lastElement: string = array.at(-1) ?? ''

    array[Number(Number(array.length) - 1)] = `@${item?.username} `

    if (messageInput) {
      setMessageInput(array.join(' '))
    } else {
      setMessageInput(`@${item?.username} `)
    }
    setSearchUserGroup(false)
  }

  const onChangeMesssage = (value: string) => {
    const regex = /@/
    let array = value.split(' ')
    const lastElement: string = array.at(-1) ?? ''

    if (regex.test(lastElement)) {
      setSearchUserGroup(true)
    } else {
      setSearchUserGroup(false)
    }
    setMessageInput(value)
  }

  return (
    <>
      <MessageInfo chatRoom={chatRoom} />
      <div
        className={`message-box message-box-fe ${
          messageInfoShow ? ' active' : ''
        }`}
      >
        <div
          className="chat-info-header chat-info-header-fe border-0"
          style={{ background: '#1F2020', borderBottomColor: '#1F2020' }}
        >
          <div className="d-flex align-items-center text-sm text-gray-neutral-700">
            {showListPin === false && (
              <>
                <div
                  onClick={() => navigate('/')}
                  className="d-block d-md-none me-2"
                  style={{ marginLeft: '-10px' }}
                >
                  <BsArrowLeftShort size={33} />
                </div>
                <div className="me-12">{handleChatAvatar(chatRoom, user)}</div>
                <div
                  className="cursor-pointer"
                  onClick={() => dispatch(toggleMessageInfo(!messageInfoShow))}
                >
                  <p className="m-0 mb-1 text-medium-sm text-white">
                    {handleChatRoomName(chatRoom, user)}
                  </p>
                  <p className="m-0 text-xs text-gray-modern-500">
                    {chatRoom?.isGroup == 1
                      ? chatRoom?.users?.length + ' '
                      : handleChatcheckIsOnline(chatRoom, user)}
                    {chatRoom?.isGroup == 1 ? (
                      <TranslateComponent stringText="thành viên" />
                    ) : (
                      ''
                    )}
                  </p>
                </div>
              </>
            )}

            {showListPin && (
              <>
                <div className='d-flex align-items-center gap-3'>
                  <div className='cursor-pointer' onClick={showAll}>
                    <FiArrowLeft color="#fff" size={25} />
                  </div>
                  <div style={{ fontSize: 14, color: "#fff" }}>{listMessage?.length} Message Pin</div>
                </div>
              </>
            )}
          </div>
          <span className="text-gray-modern-500 text-center">
            {/* <SearchIcon className="me-12 cursor-pointer" />
            <PhoneIcon className="me-12 cursor-pointer" /> */}
            <DotsVerticalIcon onClick={toggle} className="cursor-pointer" />
          </span>
        </div>

        {showListPin === false && pin && (
          <div
            className="px-3 py-1 d-flex justify-content-between align-items-center gap-2"
            style={{ background: 'rgb(24 24 24)' }}
          >
            <div style={{ fontSize: 14, width: "calc(100% - 50px" }}>
              <div style={{ color: 'rgb(118, 106, 200)', fontWeight: 600 }}>
                {pin?.sender?.username}
              </div>
              <div className='text-truncate-custom text-truncate-1' style={{ opacity: 0.7 }}>{pin?.content}</div>
            </div>
            <div className="d-flex gap-2" style={{ fontSize: 10, width: 50 }}>
              <div
                className="cursor-pointer"
                onClick={() => {
                  pinMessage(pin?._id, 0)
                }}
              >
                <BsX size={22} />
              </div>
              <div onClick={() => listPin()} className="cursor-pointer">
                <AiOutlineUnorderedList size={19} />
              </div>
            </div>
          </div>
        )}

        <div className="background-message background-message-list-fe px-3">
          <div className="container-custom-fe">
            <div
              className={pin ? 'chat-box-container chat-box-container-pin-fe' : 'chat-box-container chat-box-container-fe'}
              ref={listMessageRef}
            >
              {loading ? (
                <div className="text-center">
                  <CSpinner className="text-primary" />
                </div>
              ) : null}

              <PhotoProvider>
                {messages.map((msg, index) =>
                  isSender(msg.senderId) ? (
                    <div
                      ref={(el) => {
                        if (el) {
                          messageRefs.current[msg._id] = el
                        }
                      }}
                      key={`chat-${index}`}
                      className="w-100 d-flex justify-content-end"
                      style={{
                        marginBottom:
                          index + 1 === messages.length
                            ? 0
                            : isSenderHasPreviousMsg(msg, index)
                            ? '4px'
                            : '16px',
                      }}
                    >
                      <div className="box-action-message position-relative action-message-box-fe">
                        <DotsVerticalIcon className="cursor-pointer" />
                        <div
                          className="d-flex flex-column py-2 rounded-3 position-absolute action-message-box"
                          style={{
                            background: '#1f2020',
                            minWidth: 120,
                            top: 0,
                            left: 20,
                            zIndex: 1,
                          }}
                        >
                          <div
                            className="d-flex gap-3 px-2 py-1 align-items-center cursor-pointer"
                            style={{ fontSize: 12, opacity: 0.8 }}
                            onClick={() => handleReply(msg)}
                          >
                            <BsReply color="#fff" size={22} />
                            Reply
                          </div>
                          <div
                            className="d-flex gap-3 px-2 py-1 align-items-center cursor-pointer"
                            style={{ fontSize: 12, opacity: 0.8 }}
                            onClick={() => pinMessage(msg?._id)}
                          >
                            <AiOutlinePushpin color="#fff" size={22} />
                            Pin
                          </div>
                        </div>
                      </div>

                      <div
                        className="chat-box"
                        style={{
                          maxWidth: '70%',
                          width: 'fit-content',
                          background: 'rgb(118,106,200)',
                          color: '#fff',
                        }}
                      >
                        {renderReply(msg)}
                        {renderContent(msg)}
                        <div
                          className="d-flex justify-content-between gap-3 mt-3"
                          style={{ fontSize: 10 }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              color: '#fff',
                              opacity: 0.6,
                            }}
                          >
                            {dayjs(msg.timestamp).format('HH:mm DD/MM/YYYY')}
                          </div>
                          <div
                            style={{ color: '#fff', opacity: 0.6 }}
                            className="cursor-pointer"
                            onClick={() => removeMessage(msg._id)}
                          >
                            <TranslateComponent stringText="Xóa" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      ref={(el) => {
                        if (el) {
                          messageRefs.current[msg._id] = el
                        }
                      }}
                      key={`chat-${index}`}
                      className="d-flex align-items-end"
                      style={{
                        marginBottom:
                          index + 1 === messages.length
                            ? 0
                            : isSenderHasPreviousMsg(msg, index)
                            ? '4px'
                            : '16px',
                      }}
                    >
                      <div className="avatar-32 me-2">
                        {isSenderHasPreviousMsg(msg, index) ? null : (
                          <CAvatar
                            src={msg.sender?.avatar || avatar1}
                            className="avatar-32"
                          />
                        )}
                      </div>
                      <div className="w-100 d-flex flex-column">
                        {isSenderHasNextMessage(msg, index) ||
                        chatRoom.number_of_members === 2 ? null : (
                          <p className="text-xs text-gray-modern-500 mb-1">
                            {msg.sender?.fullName || msg.sender?.username}
                          </p>
                        )}

                        <div className="w-100 d-flex">
                          <div
                            className="chat-box"
                            style={{
                              maxWidth: '70%',
                              width: 'fit-content',
                              background: 'rgb(33,33,33)',
                              color: '#fff',
                            }}
                          >
                            {renderReply(msg)}
                            {renderContent(msg)}
                            <div
                              className="d-flex justify-content-between gap-3 mt-3"
                              style={{ fontSize: 10 }}
                            >
                              <div
                                style={{
                                  fontSize: 10,
                                  color: 'rgb(104 108 114 / 75%)',
                                }}
                              >
                                {dayjs(msg.timestamp).format(
                                  'HH:mm DD/MM/YYYY',
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="box-action-message position-relative action-message-box-fe">
                            <DotsVerticalIcon className="cursor-pointer" />
                            <div
                              className="d-flex flex-column py-2 rounded-3 position-absolute action-message-box"
                              style={{
                                background: '#1f2020',
                                minWidth: 120,
                                top: 0,
                                left: 20,
                                zIndex: 1,
                              }}
                            >
                              <div
                                className="d-flex gap-3 px-2 py-1 align-items-center cursor-pointer"
                                style={{ fontSize: 12, opacity: 0.8 }}
                                onClick={() => handleReply(msg)}
                              >
                                <BsReply color="#fff" size={22} />
                                Reply
                              </div>
                              <div
                                className="d-flex gap-3 px-2 py-1 align-items-center cursor-pointer"
                                style={{ fontSize: 12, opacity: 0.8 }}
                                onClick={() => pinMessage(msg?._id)}
                              >
                                <AiOutlinePushpin color="#fff" size={22} />
                                Pin
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                )}
              </PhotoProvider>
            </div>
            <div className="chat-input-container chat-input-container-fe d-flex align-items-end gap-3 position-relative">
              <div className={'w-100 d-flex flex-column'}>
                {searchUserGroup ? renderUserInGroup(chatRoom) : ''}

                {reply && (
                  <div
                    className="px-3 w-100 d-flex justify-content-between align-items-center"
                    style={{
                      height: 56,
                      background: '#1f2020',
                      marginBottom: '-5px',
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                    }}
                  >
                    <div className="d-flex gap-3 w-100 align-items-center">
                      <BsReply color="rgb(118, 106, 200)" size={24} />
                      <div
                        className="w-100"
                        style={{
                          background: 'rgb(63 60 81)',
                          borderRadius: 4,
                          padding: '3px 6px',
                        }}
                      >
                        <div
                          style={{
                            fontSize: 12,
                            color: 'rgb(118, 106, 200)',
                            fontWeight: 600,
                          }}
                        >
                          {reply.sender.username}
                        </div>
                        <div
                          className="text-truncate-custom text-truncate-1"
                          style={{ fontSize: 12, color: '#fff' }}
                        >
                          {renderContentReply(reply)}
                        </div>
                      </div>
                    </div>

                    <div className="cursor-pointer" onClick={handleCloseReply}>
                      <BsX color="rgb(118, 106, 200)" size={24} />
                    </div>
                  </div>
                )}

                <div className="d-flex w-100 box-form-chat">
                  <label
                    className="ps-3 button-action"
                    // htmlFor="file"
                    style={{
                      cursor: 'pointer',
                      height: 56,
                      alignContent: 'center',
                    }}
                  >
                    <div
                      className="position-absolute action-send-image-and-document align-items-center"
                      style={{ left: 0, top: -73, borderRadius: 5 }}
                    >
                      <label
                        htmlFor="file"
                        className="item-a d-flex px-3 gap-2 py-2 cursor-pointer fw-bold"
                        style={{ fontSize: 13 }}
                      >
                        <AiOutlinePicture color="#707579" size={20} />
                        <div>Photo</div>
                      </label>
                      <label
                        htmlFor="video"
                        className="item-a d-flex px-3 gap-2 py-2 cursor-pointer fw-bold align-items-center"
                        style={{ fontSize: 13 }}
                      >
                        <AiOutlineFile color="#707579" size={20} />
                        <div>Video</div>
                      </label>
                      <label
                        htmlFor="document"
                        className="item-a d-flex px-3 gap-2 py-2 cursor-pointer fw-bold align-items-center"
                        style={{ fontSize: 13 }}
                      >
                        <AiOutlineFile color="#707579" size={20} />
                        <div>Document</div>
                      </label>
                      
                    </div>
                    {/* <ImageSendIcon /> */}
                    <GrAttachment color="#9aa4b2" size={24} />
                  </label>

                  <input
                    type="file"
                    id="video"
                    accept="video/*"
                    className="d-none"
                    onChange={onChangeVideo}
                    ref={videoInput}
                  />

                  <input
                    type="file"
                    id="file"
                    className="d-none"
                    onChange={onChangeFile}
                    ref={fileInput}
                  />

                  <input
                    type="file"
                    id="document"
                    className="d-none"
                    onChange={onChangeDocument}
                    ref={documentInput}
                  />
                  <CInputGroup className="input-start-group">
                    <CFormTextarea
                      className="input-message"
                      style={{ minHeight: 56 }}
                      placeholder="Message..."
                      name="content"
                      autoComplete="off"
                      value={messageInput}
                      onChange={(e) => onChangeMesssage(e.target.value)}
                      onKeyDown={handleKeyDown}
                      onFocus={() => {
                        seenMessage(+roomId)
                      }}
                    />
                  </CInputGroup>
                </div>
              </div>

              <CInputGroupText
                onClick={sendMessage}
                className="cursor-pointer flex-shrink-0"
                style={{ width: 56, height: 56, background: '#1f2020' }}
              >
                <BiSolidSend color="rgb(154, 164, 178)" size={22} />
              </CInputGroupText>
            </div>
          </div>
        </div>
        <ModalInfo />

        {
          showVideo ? <SModal className="modal-fulzise" visible={showVideo ? true : false}>
          <div className='box-close' onClick={() => setShowVideo(false)}>
            <AiFillCloseCircle size={30} />
          </div>

          <CModalBody>
            <iframe src={'https://chat.k8chauphi.site/view.html?src=' +  showVideo} ></iframe>
          </CModalBody>

        </SModal> : ''
        }

      </div>
    </>
  )
}

export default Message

import { uploadDocument, uploadFile } from '@/components/modules/sharedApi/shared.api';
import { FileType } from '@/components/shared/TaskUploadContainer/FormikMultiFileUpload';
import dayjs, { Dayjs } from 'dayjs';
import { IFileUpload } from '../model/fileUpload.model';
import { IParams } from '../shared-interfaces';
import { IGame } from '../model/game.model';

export const checkIsLocalhost = (): boolean => {
  const isLocalHost =
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    Boolean(window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
  return isLocalHost;
};

export const createIndexes = <T, G extends IParams>(data: T[], filter: G) => {
  const { page, limit } = filter;
  return data.map((element, index) => ({
    ...element,
    index: (page - 1) * limit + index + 1,
  }));
};


export const getCurrrentDraw = (data: IGame[]) => {
  const currentTime = dayjs().valueOf();
  return data.find((game, index) => {
    const startTime = dayjs(game.startTime).valueOf();
    const endTime = dayjs(game.endTime).valueOf()
    if (currentTime >= startTime && currentTime <= endTime) {
      return index;
    }
  })
};

const noMoreThanOneCommas = (input: number | string) => {
  const str = input.toString();
  let commasCount = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '.') commasCount++;
    if (commasCount > 1) break;
  }
  return commasCount <= 1;
};

export const formatDate = (input: string | Date | undefined, format: string): string => {
  return dayjs(input).format(format);
};

export const insertCommas = (input: number | undefined | string, decimals: number = 4) => {
  if (typeof input === 'undefined') return '';
  if (!noMoreThanOneCommas(input)) return '';
  const parts = input.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts[1]) parts[1] = parts[1].substring(0, decimals); // Only take the first 4 decimals
  return parts.join('.');
};

export const unInsertCommas = (input: string) => {
  const parts = input.split('.');
  parts[0] = parts[0].replaceAll(',', '');
  if (parts[1]) parts[1] = parts[1].substring(0, 4); // Only take the first 4 decimals
  return parts.join('.');
};

export const getEllipsisTxt = (str: string, n = 5) => {
  if (str) {
    return str.length > n ? `${str.slice(0, n)}...${str.slice(str.length - n)}` : str;
  }
  return '';
};

export const getTruncateTxt = (str: string | undefined, n = 10) => {
  if (str) {
    return str.length > n ? `${str.substring(0, n)}...` : str;
  }
  return '';
};

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const handleJsonParseArr = (jsonString: string | undefined) => {
  if (!jsonString) return [];
  // try catch to handle JSON.parse
  try {
    const array: string[] = jsonString ? JSON.parse(jsonString) : [];
    return array;
  } catch (e) {
    return [];
  }
};

export const handleJsonParseFileArr = (jsonString: string | undefined) => {
  if (!jsonString) return [];
  // try catch to handle JSON.parse
  try {
    const array: FileType[] = jsonString ? JSON.parse(jsonString) : [];
    return array;
  } catch (e) {
    return [];
  }
};

export const handleJsonParseFileType = (jsonString: string | undefined): FileType | null => {
  if (!jsonString) return null;
  // try catch to handle JSON.parse
  try {
    const fileType: FileType = jsonString ? JSON.parse(jsonString) : null;
    return fileType;
  } catch (e) {
    return null;
  }
};

export const checkIsDateBefore = (startDate: Dayjs, endDate: Dayjs) => {
  if (startDate.isBefore(endDate)) return true;
  return false;
};

export const checkIsDateAfter = (startDate: Dayjs, endDate: Dayjs) => {
  if (startDate.isAfter(endDate)) return true;
  return false;
};

export const handleUploadImage = async (file: File | undefined) => {
  if (!file) return '';
  try {
    const newImagePromies: IFileUpload = await uploadFile([file]);
    const newImageUrlArr = newImagePromies.photo_url.map((item) => item.image_url);
    return newImageUrlArr[0];
  } catch (e) {
    throw Error('Error upload file');
  }
};

export const handleUploadDocument = async (file: File | undefined) => {
  if (!file) return '';
  try {
    const newImagePromies: IFileUpload = await uploadDocument([file]);
    console.log(newImagePromies);
    const newImageUrlArr = newImagePromies.photo_url.map((item) => item.image_url);
    return newImageUrlArr[0];
  } catch (e) {
    throw Error('Error upload file');
  }
};


const handleRemapingFile = (fileArray: FileType[], urlArr: string[]) => {
  const resultArr = fileArray.map((file, index) => {
    const fileObj: FileType = {
      ...file,
      file: undefined,
      dataURL: urlArr[index],
    };
    return fileObj;
  });
  return resultArr;
};

export const handleUploadMultipleFile = async (fileArray: FileType[]) => {
  if (fileArray.length === 0) return '';
  try {
    const prevFile = fileArray.filter((item) => item.file === undefined).map((item) => item);
    const newFileArr: File[] = fileArray.filter((item) => item.file !== undefined).map((item) => item.file!);
    const newFilePromies: IFileUpload = await uploadFile(newFileArr);
    const newFileObj: FileType[] = fileArray.filter((item) => item.file !== undefined);
    const newFileUrlArr = newFilePromies.photo_url.map((item) => item.image_url);
    const newMappingFileArr = handleRemapingFile(newFileObj, newFileUrlArr);
    const newFileArrResult =
      prevFile && prevFile.length > 0 ? [...prevFile, ...newMappingFileArr] : [...newMappingFileArr];
    const formatFilesUrl = JSON.stringify(newFileArrResult);
    return formatFilesUrl;
  } catch (e) {
    throw Error('Error upload file');
  }
};

export const handleUploadFileType = async (fileType: FileType) => {
  if (!fileType) return '';
  if (fileType.file === undefined) return JSON.stringify(fileType);
  try {
    const newImagePromies: IFileUpload = await uploadFile([fileType.file]);
    const newImageUrlArr = newImagePromies.photo_url.map((item) => item.image_url);
    const fileTypeResult: FileType = { ...fileType, file: undefined, dataURL: newImageUrlArr[0] };
    const formatFilesUrl = JSON.stringify(fileTypeResult);
    return formatFilesUrl;
  } catch (e) {
    throw Error('Error upload file');
  }
};


export const formatVND = (amount: number) => {
  return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(amount);
};

export const formatUSD = (amount: number) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
};


// format usd no currentcy
export const formatUSDNoCurrency = (amount: number) => {
  return new Intl.NumberFormat('en-US').format(amount);
};

import { useRouter } from '@/shared/utils/hooks/useRouter'
import { CAvatar, CButton } from '@coreui/react-pro'
import avatar1 from '@/assets/img/images.png'
import axios from '@/shared/config/axios-interceptor'
import { useEffect, useState } from 'react'
import { IChat } from '@/shared/model/chat.model'
import TranslateComponent from '@/shared/utils/googleTranslate'
import { RootState } from '@/reducers'
import { useSelector } from 'react-redux'

const InviteGroup = () => {
  const { params, navigate } = useRouter()

  const [room, setRoom] = useState<IChat>()
  const { user } = useSelector((state: RootState) => state.authentication)

  console.log(room)

  const getUserByRome = async () => {
    try {
      const response = await axios.get(
        '/room/detail/' + params.roomId + '?isGroup=1',
      )
      setRoom(response?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getUserByRome()
  }, [params.roomId])

  useEffect(() => {
    if (room?.users) {
      if (room?.users?.find((i) => i.id === user?.id)) {
        navigate(`/${params.roomId}`)
      }
    }
  }, [room])

  const joinGroup = async () => {
    const api = await axios.post('/room/add-member', {
      userIds: [user?.id],
      roomId: room?.id,
    })
    if (api) {
      navigate(`/${params.roomId}`)
    }
  }

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        background: 'rgb(31, 32, 32)',
        height: '100svh',
        width: '100svw',
      }}
    >
      <div className="text-center">
        <CAvatar
          style={{ width: 60, height: 60 }}
          src={room?.avatar || avatar1}
        />
        <h3>{room?.name}</h3>
        <div>
          <span style={{ opacity: 0.5 }}>
            {room?.users?.length} <TranslateComponent stringText="Thành viên" />
          </span>
        </div>
        <CButton onClick={joinGroup} className="mt-4">
          <TranslateComponent stringText="Tham gia nhóm" />
        </CButton>
      </div>
    </div>
  )
}

export default InviteGroup

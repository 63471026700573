import { ToastSuccess } from '@/components/shared/toast/Toast';
import {
  CButton,
  CFormInput,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSmartTable,
} from '@coreui/react-pro';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { ResponseStatusOtp, mapResponseStatusOtp } from '../../../shared/enumeration/ResponseStatus';
import { IUser } from '../../../shared/model/user.model';
import { createIndexes } from '../../../shared/utils/ultils';
import { AppDispatch } from '../../../store';
import CustomTableFooter from '../../shared/CustomTableFooter/CustomTableFooter';
import FilterIcon from '../../shared/icons/FilterIcon';
import PlusIcon from '../../shared/icons/PlusIcon';
import SearchIcon from '../../shared/icons/SearchIcon';
import { getEntities, updateEntity } from './usersOtpManagement.api';
import { fetching, resetEntity, setFilterState, userOtpSelectors } from './usersOtpManagement.reducer';
import dayjs from 'dayjs';
import { IUserOtp } from '@/shared/model/userOtp.model';

const columns = [
  {
    key: 'id',
    label: 'ID',
    sorter: false,
  },
  {
    key: 'username',
    label: 'Tên đăng nhập',
    sorter: false,
  },
  {
    key: 'otp',
    label: 'OTP',
    sorter: false,
  },
  {
    key: 'createdDate',
    label: 'Ngày tạo',
    sorter: false,
  },
  {
    key: 'status',
    label: 'Duyệt',
    sorter: false,
  },
];

interface IIndexUser extends IUserOtp {
  index: number;
}

const UserOtpManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector((state: RootState) => state.usersOtpReducer);
  const { filterState, totalItems, totalPages, loading, updateEntitySuccess, deleteEntitySuccess } = initialState;
  const [createUser, setCreateUser] = useState<boolean>(false);
  const [updateUser, setUpdateUser] = useState<IUser | null>(null);

  const handleCreateUser = () => {
    setCreateUser(true);
  };

  const handleUpdateUser = (user: IUser) => () => {
    setUpdateUser(user);
  };

  const handlePaginationChange = (page: number) => {
    if (page !== 0) {
      window.scrollTo(0, 0);
      dispatch(setFilterState({ ...filterState, page: page }));
    }
  };

  const users = useSelector(userOtpSelectors.selectAll);
  const indexedUser = createIndexes(users, filterState);

  const handleUpdateStatus = (user: IUserOtp) => () => {
    const status = user.status === ResponseStatusOtp.APPROVED ? ResponseStatusOtp.PENDING : ResponseStatusOtp.APPROVED;
    dispatch(fetching());
    dispatch(updateEntity({ ...user, status }));
  };

  useEffect(() => {
    if (updateEntitySuccess && !createUser && !updateUser) {
      ToastSuccess('Cập nhật thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  useEffect(() => {
    if (!deleteEntitySuccess) {
      dispatch(fetching());
      dispatch(getEntities(filterState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), deleteEntitySuccess]);


  const [keyword, setKeyword] = useState<string>('');

  const onSearch = () => {
    dispatch(getEntities({ ...filterState, keyword }));
  }


  return (
    <>
      <div className="tab-outlet-content">
        <div className="table-context">
          <div className='d-flex'>
            <CFormInput value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="ID hoặc Tên đăng nhập" />
            <CButton style={{ height: 42, whiteSpace: 'nowrap' }} className="ms-2 btn-custom btn-sm text-white" onClick={onSearch}>
              Tìm kiếm
            </CButton>
          </div>
          <div>
            <CButton className="btn-custom primary-500 btn-sm" onClick={handleCreateUser}>
              <PlusIcon /> Tạo tài khoản mới
            </CButton>
          </div>
          <div className="d-none">
            <CInputGroup className="search-group me-2">
              <CInputGroupText id="search-addon">
                <SearchIcon />
              </CInputGroupText>
              <CFormInput placeholder="Tìm kiếm theo tên" aria-label="Username" aria-describedby="basic-addon1" />
            </CInputGroup>
            <CButton className="btn-custom gray-700 btn-sm">
              <FilterIcon /> Lọc
            </CButton>
          </div>
        </div>

        <div className="table-content">
          <CSmartTable
            header
            columns={columns}
            items={indexedUser}
            clickableRows
            noItemsLabel="Không có bản ghi"
            itemsPerPage={filterState.limit}
            scopedColumns={{
              createdDate: ({ index, ...value }: IIndexUser) => (
                <td>
                  <div className="d-flex align-items-center flex-nowrap">
                    {dayjs(value.createdDate).format('DD/MM/YYYY HH:mm:ss')}
                  </div>
                </td>
              ),
              status: ({ index, ...value }: IIndexUser) => (
                <td>
                  <div className="d-flex align-items-center flex-nowrap">
                    <CFormSwitch
                      checked={value.status === ResponseStatusOtp.APPROVED}
                      className="me-2"
                      onChange={handleUpdateStatus(value)}
                      disabled={loading}
                    />
                    {mapResponseStatusOtp[value?.status ?? ResponseStatusOtp.PENDING]}
                  </div>
                </td>
              ),

            }}
            tableProps={{
              hover: true,
              align: 'middle',
              responsive: 'lg',
              className: 'custom-table',
            }}
            tableHeadProps={{
              className: 'table-head',
            }}
            tableBodyProps={{
              className: 'table-body',
            }}
          />
        </div>
        <CRow>
          <CustomTableFooter
            totalItems={totalItems}
            hideSideChosen
            totalPages={totalPages}
            filterState={filterState}
            setFilterState={(filter) => dispatch(setFilterState(filter))}
            handlePaginationChange={handlePaginationChange}
          />
        </CRow>
      </div>
    </>
  );
};

export default UserOtpManagement;

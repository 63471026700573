import { ToastSuccess } from "@/components/shared/toast/Toast";
import { RootState } from "@/reducers";
import { AppDispatch } from "@/store";
import { CButton, CCol, CForm, CFormInput, CFormLabel, CRow } from "@coreui/react-pro";
import { Formik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslateComponent from "@/shared/utils/googleTranslate";
import { updatePassword } from "../../chat.api";
import { IChat } from "@/shared/model/chat.model";
import { fetching, resetAll } from "../../chat.reducer";

interface IProps {
    chatRoom: IChat
}
const SetPassword = ({ chatRoom }: IProps) => {

    const dispatch = useDispatch<AppDispatch>()

    const { initialState } = useSelector((state: RootState) => state.chatReducerFe)
    const { updatePasswordSuccess } = initialState


    const initialValues: IChat = {
        id: '',
        name: '',
        avatar: '',
        users: [],
        password: ''
    }

    useEffect(() => {
        if (updatePasswordSuccess) {
            dispatch(resetAll())
            ToastSuccess('Cập nhật mật khẩu thành công')
        }
    }, [updatePasswordSuccess])

    return <Formik
        enableReinitialize
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={async (value, { resetForm }) => {
            dispatch(fetching())
            value.roomId = +chatRoom?.id
            value.password = value.password
            dispatch(updatePassword(value as IChat))
            resetForm()
        }}
    >
        {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleSubmit,
            resetForm,
            handleBlur,
        }) => (
            <CForm onSubmit={handleSubmit} className="form-add-group custom-form-edit-profile">
                <div className={"px-3 my-3"}>
                    <CRow className={"mb-3 mt-4"}>
                        <CCol xs={12}>
                            <div className="box-custom-form-login">
                                <CFormLabel htmlFor='username' style={{ background: "#1F2020", color: "#fff" }}>New Passowrd</CFormLabel>
                                <CFormInput style={{ background: "transparent", color: "#fff" }}
                                    value={values.password}
                                    onChange={handleChange}
                                    type="text"
                                    id="password"
                                    name="password"
                                    autoComplete="none"
                                    onBlur={handleBlur}
                                />
                            </div>
                        </CCol>
                    </CRow>
                    <CButton
                        type="submit"
                        className="w-100"
                        style={{ background: 'rgb(51,144,236)', boxShadow: 'none' }}
                    >
                        <TranslateComponent stringText={'Cập nhật'} />
                    </CButton>

                </div>

            </CForm>
        )}
    </Formik>
}

export default SetPassword
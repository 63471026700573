// import React, { useState } from 'react'
// import axios from 'axios'

// const targetLanguage = localStorage.getItem('language') || 'en'

// export const googleTranslate =   (text: string) => {
//   return text;

//   const apiKey = 'AIzaSyBSWp5Thxen_uc8R2oIdl8FsL1bUyR3FIA' // Replace with your API key
//   const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`

//   try {
//     const response = await axios.post(url, {
//       q: text,
//       target: targetLanguage,
//     })

//     return response.data.data.translations[0].translatedText
//     //   setTranslatedText(response.data.data.translations[0].translatedText);
//   } catch (error) {
//     return text
//     console.error('Error translating text:', error)
//   }
// }


import React, { useEffect, useState } from 'react';
import axios from 'axios';

const targetLanguage = localStorage.getItem('lang') || 'en'
interface Iprops {
  stringText: string
}
const TranslateComponent = ({ stringText = '' }: Iprops) => {
  // const [text, setText] = useState('');

  // const apiKey = 'AIzaSyDDabc9xNXpNeo2J6DPCCm8zqayg9E6r24' // Replace with your API key

  // const handleTranslate = async () => {
  //   const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

  //   try {
  //     const response = await axios.post(url, {
  //       q: stringText,
  //       target: targetLanguage,
  //     });

  //     setText(response.data.data.translations[0].translatedText);
  //   } catch (error) {
  //     console.error('Error translating text:', error);
  //   }
  // };

  // useEffect(() => {
  //   handleTranslate();
  // }, [text, targetLanguage, stringText]);

  return <>{stringText}</>;
};

export default TranslateComponent;

import SModal from '@/components/shared/Modal/SModal'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { CAvatar, CModalBody, CModalHeader } from '@coreui/react-pro'
import avatar1 from '@/assets/img/images.png'
import { useDispatch, useSelector } from 'react-redux'
import { selectEntityById } from '../chat/chat.reducer'
import { AiOutlineUserAdd } from 'react-icons/ai'
import { FiUsers } from 'react-icons/fi'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import { hiddenShowInfo } from '../../auth/auth.reducer'
import GroupInfo from './GroupInfo'
import UserInfo from './UserInfo'
import { handleChatAvatar, handleChatRoomName } from '../chat/Chat'

const ModalInfo = () => {
  const { params } = useRouter()
  const { roomId } = params
  const dispatch = useDispatch<AppDispatch>()

  const chatRoom = useSelector(selectEntityById(roomId || ''))
  const { user, showInfo } = useSelector(
    (state: RootState) => state.authentication,
  )

  if (!chatRoom) return <></>

  return (
    <SModal visible={showInfo} onClose={() => dispatch(hiddenShowInfo())}>
      <CModalHeader className="border-0">
        <h5 className="mb-0">
          {chatRoom.isGroup === 1 ? 'Group Info' : 'User Info'}
        </h5>
      </CModalHeader>

      <CModalBody className="border-0 px-0">
        <div className="d-flex gap-3 align-items-center px-3">
          {/* <CAvatar
            src={avatar1}
            className="avatar-54"
            style={{ width: 70, height: 70 }}
          /> */}
          <div className='avatar-info'>
          {handleChatAvatar(chatRoom, user)}
          </div>
          <div>
            <h5 className="m-0">{handleChatRoomName(chatRoom, user)}</h5>
            <span style={{ opacity: 0.7, fontSize: 14 }}>
              {chatRoom.isGroup === 1
                ? chatRoom.number_of_members + ' Members'
                : 'Online'}
            </span>
          </div>
        </div>

        {chatRoom.isGroup === 1 ? (
          <GroupInfo chatRoom={chatRoom} />
        ) : (
          <UserInfo chatRoom={chatRoom} />
        )}
      </CModalBody>
    </SModal>
  )
}

export default ModalInfo

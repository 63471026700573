import SearchIcon from '@/components/shared/icons/SearchIcon'
import { CFormInput, CInputGroup, CInputGroupText } from '@coreui/react-pro'
import { FiArrowLeft, FiMenu } from 'react-icons/fi'
import {
  getEntities as getUserEntities,
  IUserParams,
} from '../../UserManagement/usersManagement.api'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import TranslateComponent from '@/shared/utils/googleTranslate'
import { getEntities, getListContact } from '../chat/chat.api'

interface Iprops {
  renderAction: any
  setRenderAction: any
  searchMsgKey: any
  setSearchMsgKey: any
}
const HeaderLeft = (props: Iprops) => {
  const { renderAction, setRenderAction, searchMsgKey, setSearchMsgKey } = props
  const dispatch = useDispatch<AppDispatch>()

  const handSearch = (e: any) => {
    setSearchMsgKey(e.target.value)
    dispatch(getListContact({ page: 1, limit: 10, keyword: e.target.value }))
  }
  const render = () => {
    // Mặc định
    if (renderAction === 0) {
      return (
        <>
          <div
            onClick={() => {
              setRenderAction(2)
            }}
            className="button-action d-flex align-items-center cursor-pointer"
          >
            <FiMenu color="#707579" size={25} />
          </div>
          <CInputGroup className="input-start-group">
            <CInputGroupText id="search-addon" className="cursor-pointer">
              <SearchIcon color="#fff" height={16} width={16} />
            </CInputGroupText>
            <CFormInput
              placeholder="Search"
              value={searchMsgKey}
              name="key"
              onClick={() => setRenderAction(1)}
              onChange={(e) => setSearchMsgKey(e.currentTarget.value)}
            />
          </CInputGroup>
        </>
      )
    }

    // Màn search
    if (renderAction === 1) {
      return (
        <>
          <div
            className="button-action d-flex align-items-center cursor-pointer"
            onClick={() => {
              setRenderAction(0)
              setSearchMsgKey('')
            }}
          >
            <FiArrowLeft color="#707579" size={25} />
          </div>
          {/* <ArrowNarrowLeftIcon className="me-2 cursor-pointer" /> */}
          <CInputGroup className="input-start-group">
            <CInputGroupText id="search-addon" className="cursor-pointer">
              <SearchIcon color="#fff" height={16} width={16} />
            </CInputGroupText>
            <CFormInput
              placeholder="Search"
              value={searchMsgKey}
              name="key"
              onChange={handSearch}
            />
          </CInputGroup>
        </>
      )
    }

    // Màn settings
    if (renderAction === 2) {
      return (
        <>
          <div
            className="button-action d-flex align-items-center cursor-pointer"
            onClick={() => {
              setRenderAction(0)
            }}
          >
            <FiArrowLeft color="#707579" size={25} />
          </div>
          <h5 className="w-100 m-0" style={{ color: '#fff' }}>
            <TranslateComponent stringText="Settings" />
          </h5>
        </>
      )
    }

    // Màn edit profile
    if (renderAction === 3) {
      return (
        <>
          <div
            className="button-action d-flex align-items-center cursor-pointer"
            onClick={() => {
              setRenderAction(0)
            }}
          >
            <FiArrowLeft color="#707579" size={25} />
          </div>
          <h5 className="w-100 m-0" style={{ color: '#fff' }}>
            <TranslateComponent stringText="Edit profile" />
          </h5>
        </>
      )
    }

    // Màn chọn ngôn ngữ
    if (renderAction === 4) {
      return (
        <>
          <div
            className="button-action d-flex align-items-center cursor-pointer"
            onClick={() => {
              setRenderAction(0)
            }}
          >
            <FiArrowLeft color="#707579" size={25} />
          </div>
          <h5 className="w-100 m-0" style={{ color: '#fff' }}>
            <TranslateComponent stringText="Language" />
          </h5>
        </>
      )
    }

    // Thư mục
    if (renderAction === 5) {
      return (
        <>
          <div
            className="button-action d-flex align-items-center cursor-pointer"
            onClick={() => {
              setRenderAction(0)
            }}
          >
            <FiArrowLeft color="#707579" size={25} />
          </div>
          <h5 className="w-100 m-0" style={{ color: '#fff' }}>
            <TranslateComponent stringText="Folder" />
          </h5>
        </>
      )
    }

    //  Form Thư mục
    if (renderAction === 6) {
      return (
        <>
          <div
            className="button-action d-flex align-items-center cursor-pointer"
            onClick={() => {
              setRenderAction(5)
            }}
          >
            <FiArrowLeft color="#707579" size={25} />
          </div>
          <h5 className="w-100 m-0" style={{ color: '#fff' }}>
            <TranslateComponent stringText="Form Folder" />
          </h5>
        </>
      )
    }
  }

  return (
    <div
      className={`border-0 chat-info-header-fe chat-info-header flex-nowrap`}
    >
      {render()}
    </div>
  )
}

export default HeaderLeft

import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../shared/config/axios-interceptor';
import { IUser } from '../../../shared/model/user.model';
import { IParams } from '../../../shared/shared-interfaces';
import { IUserOtp } from '@/shared/model/userOtp.model';

const prefix = 'user/otp';

export interface IUserOtpParams extends IParams {
  keyword?: string;
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IUserOtpParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IUser[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IUserOtp, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IUserOtp>(`${prefix}/${id}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
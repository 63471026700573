import SModal from '@/components/shared/Modal/SModal'
import { RootState } from '@/reducers'
import { socket } from '@/shared/config/socket'
import { ResponseStatus } from '@/shared/enumeration/ResponseStatus'
import { IChat, ICreateChatResponse, INewChat } from '@/shared/model/chat.model'
import { ISelectValue } from '@/shared/shared-interfaces'
import { AppDispatch } from '@/store'
import {
  CButton,
  CCol,
  CModalBody,
  CModalHeader,
  CRow,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select, { MultiValue } from 'react-select'
import * as Yup from 'yup'
import {
  getEntities as getUserEntities,
  IUserParams,
} from '../../UserManagement/usersManagement.api'
import { IUser } from '@/shared/model/user.model'
import { forEach, unionWith } from 'lodash'
import { userSelectors } from '../../UserManagement/usersManagement.reducer'
import { getEntities, getListContact } from '../chat/chat.api'
import { chatSelectors, selectEntityById } from '../chat/chat.reducer'

export const returnUserMultipleItem = (
  roomItem: IChat[],
  selectRoom?: IChat[],
) => {
  if (!roomItem) return []
  if (selectRoom) {
    const userSelect = selectRoom.map((item) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
    const userSelectList = roomItem.map((item) => {
      return {
        value: item.id,
        label: `${item.name}`,
      }
    })

    const result = unionWith(
      userSelect,
      userSelectList,
      (a, b) => a.value === b.value,
    )

    return result
  }
  return roomItem.map((item) => {
    return {
      value: item.id,
      label: item.name,
    }
  })
}

const initialValues: INewChat = {
  users: [],
  content: '',
}

interface ICreateChatProps {
  setFieldValue: any
  values: any
  visibleSelectChat: boolean
  setVisibleSelectChat: any
  setListRoom: any
  listRoom: any
}

const AddChatToFolder = (props: ICreateChatProps) => {
  const { setFieldValue, values, visibleSelectChat, setVisibleSelectChat, setListRoom, listRoom } = props
  const dispatch = useDispatch<AppDispatch>()
  const [listId, setListId] = useState<number[]>([])
  const { user } = useSelector((state: RootState) => state.authentication)
  const { initialState } = useSelector(
    (state: RootState) => state.chatReducerFe,
  )
  const { filterState, totalPages } = initialState
  const [loading, setLoading] = useState<boolean>(false)
  const [userFilter, setUserFilter] = useState<IUserParams>({
    page: 1,
    limit: 20,
    status: ResponseStatus.ACTIVE,
  })

  const chatRooms = useSelector(chatSelectors.selectAll)

  const listRoomIds = listRoom.map((item: IChat) => item.id);
  const customRoomItems: ISelectValue<string>[] = returnUserMultipleItem(
    chatRooms?.filter((obj: any) => !listRoomIds.includes(obj.id)),
    [],
  )

  const returnMutiOption = (
    optionValue: number[],
    array: ISelectValue<string>[],
  ): ISelectValue<string>[] => {
    if (!optionValue) return []
    
    const selectValue = array.filter((item) =>
      optionValue.includes(Number(item.value)),
    )
    return selectValue
  }

  useEffect(() => {
    dispatch(getEntities(userFilter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectHandler = () => {
    let list: IChat[] = []
    forEach(listId, (item) => {
        const config: IChat[] = chatRooms?.filter((i) => Number(i.id) === item)
        if (config.length) list.push(...config)
    })
    setListRoom([...listRoom, ...list])
    setFieldValue(`userIds`, listId)
  }

  return (
    <SModal visible={props.visibleSelectChat} onClose={() => props.setVisibleSelectChat(false)}>
      <CModalBody>
        <CRow className="g-4">
          <CCol>
            <h5>Add chats</h5>
          </CCol>
          <CCol xs={12} className="mb-24">
            <Select
              defaultValue={null}
              className="custom-select multi-select height-44"
              classNamePrefix="react-select"
              value={returnMutiOption(listId || [], customRoomItems)}
              onChange={async (
                newValue: MultiValue<ISelectValue<string> | null>,
              ) => {
                if (newValue.length <= 10) {
                  if (newValue.length === 0) {
                    setUserFilter({
                      page: 1,
                      limit: 20,
                      status: ResponseStatus.ACTIVE,
                    })
                  }
                  const optionValue = newValue.map((item) =>
                    Number(item?.value),
                  )
                  setListId(optionValue)
                }
              }}
              onMenuScrollToBottom={() => {
                setUserFilter({ ...userFilter, limit: userFilter.limit + 20 })
              }}
              onInputChange={(newValue: string) => {
                setUserFilter({ ...userFilter, key: newValue })
              }}
              id={'users'}
              noOptionsMessage={() => <>No chat</>}
              options={customRoomItems}
              isClearable={true}
              isMulti
              closeMenuOnSelect={false}
              menuPosition={'absolute'}
              placeholder={'Search'}
            />
          </CCol>
        </CRow>

        <div className='d-flex justify-content-end gap-3'>
          <CButton onClick={() => props.setVisibleSelectChat(false)} size='sm' className='bg-danger'>Hủy</CButton>
          <CButton onClick={() => {
            props.setVisibleSelectChat(false)
            selectHandler()
          }} size='sm' style={{ background: 'rgb(51,144,236)' }}>Thêm</CButton>
        </div>
      </CModalBody>
    </SModal>
  )
}

export default AddChatToFolder

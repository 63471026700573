import { checkErrorInputFile } from '@/components/shared/AvatarUpload/AvatarUploadContainer'
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'
import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { handleUploadImage } from '@/shared/utils/ultils'
import { AppDispatch } from '@/store'
import { cisCameraPlus } from '@coreui/icons-pro'
import CIcon from '@coreui/icons-react'
import {
  CAvatar,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetching } from '../chat/chat.reducer'
import { IUser } from '@/shared/model/user.model'
import { updateEntity } from '../../UserManagement/usersManagement.api'
import { getProfile } from '../../auth/auth.api'
import { resetAll } from '../../UserManagement/usersManagement.reducer'
import TranslateComponent from '@/shared/utils/googleTranslate'
import { AiOutlineFolderOpen, AiOutlinePlus } from 'react-icons/ai'
import axios from '../../../../shared/config/axios-interceptor'
import { IFolder } from '@/shared/model/folder.model'

interface IProps {
  setRenderAction: any
  renderAction: any
  setIdFolder: any
  idFolder: any
}
const ChatFolder = ({
  setRenderAction,
  renderAction,
  setIdFolder,
  idFolder,
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication)

  const { initialState } = useSelector((state: RootState) => state.usersReducer)
  const { updateEntitySuccess } = initialState
  const [folders, setFolders] = useState<IFolder[]>([])

  const getFolders = async () => {
    try {
      const response = await axios.get('/room/get-folder-by-user  ')
      if (response.status === 200) {
        setFolders(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getFolders()
  }, [])

  useEffect(() => {
    dispatch(getProfile())
    dispatch(resetAll())
  }, [updateEntitySuccess])

  const handlerDeleteFolder = async (id: any) => {
    try {
      const response = await axios.delete(`/room/folder/${id}`)
      if (response.status === 200) {
        getFolders()
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (!user) return <></>

  return (
    <>
      <div>
        <div
          className="text-center p-3"
          style={{
            borderBottomWidth: 10,
            borderBlockColor: 'rgb(15,15,15)',
            borderBlockStyle: 'solid',
          }}
        >
          <AiOutlineFolderOpen size={90} color="#ddb180" />

          <div style={{ opacity: 0.5 }} className="my-3">
            Create folders for different groups of chats and quickly switch
            between them.
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <CButton
              onClick={() => {
                setRenderAction(6)
                setIdFolder(null)
              }}
              className="rounded-5 px-3 d-flex align-items-center justify-content-center gap-2"
              style={{ background: 'rgb(51, 144, 236)' }}
            >
              <AiOutlinePlus color="#fff" fill="#fff" size={20} />
              <TranslateComponent stringText="Tạo mới thư mục" />
            </CButton>
          </div>
        </div>
        <div className="p-3">
          <h6 className="px-3" style={{ opacity: 0.5 }}>
            Chat Folders
          </h6>

          <div className="list-chat-folder">
            {folders.map((item, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center item-list"
              >
                <div
                  className="w-100"
                  onClick={() => {
                    setRenderAction(6)
                    setIdFolder(item)
                  }}
                >
                  <div style={{ fontSize: 14 }}>{item.name}</div>
                  <div style={{ opacity: 0.5, fontSize: 12 }}>
                    {item?.rooms?.length ?? 0} Chats
                  </div>
                </div>
                <div
                  onClick={() => handlerDeleteFolder(item.id)}
                  style={{
                    fontSize: 12,
                    opacity: 0.9,
                    cursor: 'pointer',
                    color: '#e53935',
                    position: 'relative',
                    zIndex: 1,
                  }}
                >
                  Xóa
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default ChatFolder

import { checkErrorInputFile } from '@/components/shared/AvatarUpload/AvatarUploadContainer'
import SModal from '@/components/shared/Modal/SModal'
import { ToastError } from '@/components/shared/toast/Toast'
import { handleUploadImage } from '@/shared/utils/ultils'
import { cisCameraPlus } from '@coreui/icons-pro'
import CIcon from '@coreui/icons-react'
import {
    CAvatar,
    CForm,
    CFormInput,
    CFormLabel,
    CInputGroup,
    CInputGroupText,
    CModalBody,
    CModalHeader,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useState } from 'react'
import AddMemberGroup from './AddMemberGroup'
import { socket } from '@/shared/config/socket'
import { useDispatch, useSelector } from 'react-redux'
import { userSelectors } from '../../UserManagement/usersManagement.reducer'
import { RootState } from '@/reducers'
import { ICreateChatResponse } from '@/shared/model/chat.model'
import { AppDispatch } from '@/store'
import { hiddenAddContact, hiddenCreateGroup } from '../../auth/auth.reducer'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import TranslateComponent from '@/shared/utils/googleTranslate'
import SearchIcon from '@/components/shared/icons/SearchIcon'
import {
    getEntities as getUserEntities,
} from '../../UserManagement/usersManagement.api'
import avatar1 from '@/assets/img/images.png'
import axios from '../../../../shared/config/axios-interceptor'

interface Iprops {
    setRenderAction: any
}
const ModalAddContact = ({ setRenderAction }: Iprops) => {
    const dispatch = useDispatch<AppDispatch>()
    const [step, setStep] = useState(0)
    const { user, addContact } = useSelector(
        (state: RootState) => state.authentication,
    )
    const { navigate, params } = useRouter()

    const handleRemappingImageValue = async (value: any) => {
        if (value.fileAvatar) {
            const avatarUrlPromises = await handleUploadImage(value.fileAvatar)
            value.avatar = avatarUrlPromises
        }
        return value
    }

    const initialValues: any = {
        fileAvatar: null,
        avatar: '',
        name: null,
        users: [],
    }

    const handleFileUploadavatar = async (
        e: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined,
        ) => void,
    ) => {
        const inputFiles = e.target.files
        if (inputFiles && inputFiles[0]) {
            if (!checkErrorInputFile([inputFiles[0]])) return
            const reader = new FileReader()
            reader.onload = (e) => {
                setFieldValue('fileAvatar', inputFiles[0])
                setFieldValue('avatar', e.target?.result)
            }
            reader.readAsDataURL(inputFiles[0])
        }
    }

    const userItems = useSelector(userSelectors.selectAll)
    const handCheckStep = (name: string) => {
        if (!name) {
            return
        }
        setStep(1)
    }

    const handSearch = (e: any) => {
        dispatch(getUserEntities({ page: 1, limit: 10, keyword: e.target.value }))
    }


    const getRoom = async (userId: number, username: string) => {
        try {
          const response = await axios.get('/room/get-room-user-custom/' + userId)
          if (!response?.data?.length) {
            socket.emit('new_room', { users: [user?.id, userId], name: username })
    
            socket.on(`user_${user?.id}_new_room`, (res: any) => {
              navigate(`/${res.room?.id}`)
              setRenderAction(0)
              socket.off(`user_${user?.id}_new_room`)
              dispatch(hiddenAddContact())
            })
          } else {
            navigate(`/${response?.data[0]?.room_id}`)
            setRenderAction(0)
            dispatch(hiddenAddContact())
          }
        } catch (error) {
          console.error('Error:', error)
        }
      }
    
      const checkSendMessenge = (userId: string, username: string) => {
        getRoom(+userId, username)
      }

    return (
        <SModal
            visible={addContact}
            onClose={() => dispatch(hiddenAddContact())}
            alignment="center"
            className='modal-add-contact'
        >
            <CModalHeader className='border-0 pb-0'>
                <h6><TranslateComponent stringText='Contacts' /></h6>
            </CModalHeader>
            <CModalBody className='p-0'>
                <div
                    className={`box-search-form mt-2 pb-2`}
                >
                    <CInputGroup className="input-start-group">
                        <CInputGroupText id="search-addon" className="cursor-pointer">
                            <SearchIcon color='#fff' height={16} width={16} />
                        </CInputGroupText>
                        <CFormInput
                            placeholder="Search"
                            name="key"
                            onChange={handSearch}
                            style={{ background: "transparent", color: "#fff" }}
                        />
                    </CInputGroup>
                </div>
                <div className="search">
                    <div className="list-search">
                        <div
                            className="list-chat-fe d-block"
                        >
                            {userItems.map((user: any) => (
                                <div
                                    key={`chat-${user.id}`}
                                    className={`chat-room`}
                                    onClick={() => checkSendMessenge(user.id, user?.username ?? '')}
                                >
                                    <div className="d-flex align-items-center me-md-12">
                                        <CAvatar src={user.avatar || avatar1} className="avatar-40" />
                                    </div>
                                    <div className="d-block w-100 ms-12">
                                        <div className="d-flex justify-content-between align-items-center mb-1">
                                            <span className="chat-room-name text-white">{user.username}</span>
                                        </div>
                                        <p className="message m-0">
                                            <TranslateComponent stringText="Đang cập nhật..." />
                                        </p>
                                    </div>
                                </div>
                            ))}

                            {userItems.length === 0 ? (
                                <div className="text-center mt-3">
                                    <TranslateComponent stringText="Không có người dùng" />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </CModalBody>
        </SModal>
    )
}
export default ModalAddContact

import React, { useEffect, useState } from 'react'
import axios from 'axios'

const targetLanguage = localStorage.getItem('lang') || 'en'
interface Iprops {
  stringText: string
}
const TranslateComponentHTML = ({ stringText = '' }: Iprops) => {
  // const [text, setText] = useState('')

  // const apiKey = 'AIzaSyDDabc9xNXpNeo2J6DPCCm8zqayg9E6r24' // Replace with your API key

  // const handleTranslate = async () => {
  //   const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`

  //   try {
  //     const response = await axios.post(url, {
  //       q: stringText,
  //       target: targetLanguage,
  //     })

  //     setText(response.data.data.translations[0].translatedText)
  //   } catch (error) {
  //     console.error('Error translating text:', error)
  //   }
  // }

  // useEffect(() => {
  //   handleTranslate()
  // }, [text, targetLanguage, stringText])

  return (
    <div
      style={{ overflowWrap: 'break-word' }}
      dangerouslySetInnerHTML={{
        __html: stringText.replace(/\n/g, '<br/>'),
      }}
    ></div>
  )
}

export default TranslateComponentHTML

import { CImage } from '@coreui/react-pro'
import axios from '@/shared/config/axios-interceptor'
import { useEffect, useState } from 'react'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import 'react-photo-view/dist/react-photo-view.css';
import avatar1 from '@/assets/img/images.png'
import TranslateComponentHTML from '@/shared/utils/googleTranslateHtml'
import dayjs from 'dayjs';

interface IProps {
    chatRoom: any
}

const LinkComponet = ({ chatRoom }: IProps) => {
    const [info, setInfo] = useState([])
    const { params } = useRouter()

    const fetchPhoto = async () => {
        try {
            const res = await axios.get(
                `/message/room/${params?.roomId}?limit=50&sortBy=timestamp&sortOrder=DESC&roomId=${params?.roomId}&type=LINK`,
            )
            setInfo(res.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchPhoto()
    }, [params?.roomId])

    let pattern = /https?:\/\/[^\s]+|www\.[^\s]+|localhost|[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}(?:\/[^\s]*)?/g;

    return (
        <>
            <div className="px-4">

                {
                    info.map((item: any, index: number) => {
                        return <div className='d-flex gap-3 mb-3' key={index}>
                            <div>
                                <CImage width={50} src={item?.sender?.avatar || avatar1} />
                            </div>
                            <div className='w-100 link-info d-flex flex-column justify-content-between' style={{ overflowWrap: 'break-word', fontSize: 14 }}>
                                <TranslateComponentHTML
                                    stringText={item.content.replace(pattern, '<a target="_blank" href="$&">$&</a>')}
                                />
                                <div style={{ color: "#fff", fontSize: 12, opacity: 0.5 }}>{dayjs(item?.timestamp).format('DD/MM/YYYY HH:mm')}
                                </div>
                            </div>
                        </div>
                    })
                }

            </div>
        </>
    )
}

export default LinkComponet

import { checkErrorInputFile } from "@/components/shared/AvatarUpload/AvatarUploadContainer";
import { ToastError, ToastSuccess } from "@/components/shared/toast/Toast";
import { RootState } from "@/reducers";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import { handleUploadImage } from "@/shared/utils/ultils";
import { AppDispatch } from "@/store";
import { cisCameraPlus } from "@coreui/icons-pro";
import CIcon from "@coreui/icons-react";
import { CAvatar, CButton, CCol, CForm, CFormInput, CFormLabel, CRow } from "@coreui/react-pro";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetching } from "../chat/chat.reducer";
import { IUser } from "@/shared/model/user.model";
import { updateEntity } from "../../UserManagement/usersManagement.api";
import { getProfile } from "../../auth/auth.api";
import { resetAll } from "../../UserManagement/usersManagement.reducer";
import TranslateComponent from "@/shared/utils/googleTranslate";

const EditProfile = () => {

    const dispatch = useDispatch<AppDispatch>()
    const [step, setStep] = useState(0)
    const { user } = useSelector((state: RootState) => state.authentication)

    const { initialState } = useSelector((state: RootState) => state.usersReducer)
    const { updateEntitySuccess } = initialState
    const { navigate, params } = useRouter()


    const handleRemappingImageValue = async (value: any) => {
        if (value.fileAvatar) {
            const avatarUrlPromises = await handleUploadImage(value.fileAvatar)
            value.avatar = avatarUrlPromises
        }
        return value
    }

    const initialValues: IUser = {
        fileAvatar: null,
        avatar: '',
        username: '',
        fullName: '',
        id: '',
    }

    const handleFileUploadavatar = async (
        e: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined,
        ) => void,
    ) => {
        const inputFiles = e.target.files
        if (inputFiles && inputFiles[0]) {
            if (!checkErrorInputFile([inputFiles[0]])) return
            const reader = new FileReader()
            reader.onload = (e) => {
                setFieldValue('fileAvatar', inputFiles[0])
                setFieldValue('avatar', e.target?.result)
            }
            reader.readAsDataURL(inputFiles[0])
        }
    }

    useEffect(() => {
        dispatch(getProfile())
        dispatch(resetAll())
    }, [updateEntitySuccess])


    if(!user) return <></> 


    return <Formik
        enableReinitialize
        initialValues={user || initialValues}
        // validationSchema={validationSchema}
        onSubmit={async (value, { resetForm }) => {
            if(value.fileAvatar){
                value = await handleRemappingImageValue(value)
            }
            dispatch(fetching())
            dispatch(updateEntity(value as IUser))
            resetForm()
        }}
    >
        {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleSubmit,
            resetForm,
            handleBlur,
        }) => (
            <CForm onSubmit={handleSubmit} className="form-add-group custom-form-edit-profile">


                <div className={"px-3 my-3"}>
                    <div className='p-0 box-avatar'>
                        <div className="d-flex justify-content-center position-relative">
                            <div className="profileImageContainer">
                                <div
                                    className="position-relative box-select-file"
                                >
                                    {values.avatar ? (
                                        <CAvatar
                                            color="secondary"
                                            className="avatar-xxl object-fit-cover avatar-54"
                                            src={values.avatar}
                                            style={{ width: '100%', height: '100%' }}
                                        />
                                    ) : (
                                        <div
                                            className="d-flex flex-column justify-content-center align-items-center"
                                            style={{ width: '100%', height: '100%' }}
                                        >
                                            <CIcon
                                                icon={cisCameraPlus}
                                                className="mb-3"
                                                style={{ width: '33px', height: '33px', color: '#fff' }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <CFormLabel
                                    htmlFor="custom-task-file-input-1"
                                    className={`p-image m-0`}
                                >
                                    {values.avatar ? (
                                        <CIcon
                                            icon={cisCameraPlus}
                                            className="upload-button"
                                            style={{ color: '#fff' }}
                                        />
                                    ) : (
                                        <div className="add-image"></div>
                                    )}
                                    <input
                                        id="custom-task-file-input-1"
                                        className="file-upload"
                                        type="file"
                                        onClick={(e) => (e.currentTarget.value = '')}
                                        onChange={(e) =>
                                            handleFileUploadavatar(e, setFieldValue)
                                        }
                                        accept="image/*"
                                    />
                                </CFormLabel>
                            </div>
                        </div>
                    </div>

                    <CRow className={"mb-3 mt-4"}>
                        <CCol xs={12}>
                            <div className="box-custom-form-login">
                                <CFormLabel htmlFor='fullName' style={{ background: "#1F2020", color: "#fff" }}>Full name</CFormLabel>
                                <CFormInput style={{ background: "transparent", color: "#fff" }}
                                    value={values.fullName}
                                    onChange={handleChange}
                                    type="text"
                                    id="fullName"
                                    name="fullName"
                                    autoComplete="none"
                                    onBlur={handleBlur}
                                />
                            </div>
                        </CCol>
                    </CRow>

                    <CRow className={"mb-3 mt-4"}>
                        <CCol xs={12}>
                            <div className="box-custom-form-login">
                                <CFormLabel htmlFor='username' style={{ background: "#1F2020", color: "#fff" }}>Phone</CFormLabel>
                                <CFormInput readOnly style={{ background: "transparent", color: "#fff" }}
                                    value={values.username}
                                    onChange={handleChange}
                                    type="text"
                                    id="username"
                                    name="username"
                                    autoComplete="none"
                                    onBlur={handleBlur}
                                />
                            </div>
                        </CCol>
                    </CRow>

                    <CButton
                        type="submit"
                        className="w-100"
                        style={{ background: 'rgb(51,144,236)', boxShadow: 'none' }}
                    >
                        <TranslateComponent stringText={'Cập nhật'} />
                    </CButton>

                </div>

            </CForm>
        )}
    </Formik>
}

export default EditProfile
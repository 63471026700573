import { checkErrorInputFile } from '@/components/shared/AvatarUpload/AvatarUploadContainer'
import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { handleUploadImage } from '@/shared/utils/ultils'
import { AppDispatch } from '@/store'
import { cisCameraPlus } from '@coreui/icons-pro'
import CIcon from '@coreui/icons-react'
import {
  CAvatar,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetching } from '../chat/chat.reducer'
import { IUser } from '@/shared/model/user.model'
import { updateEntity } from '../../UserManagement/usersManagement.api'
import { getProfile } from '../../auth/auth.api'
import { resetAll } from '../../UserManagement/usersManagement.reducer'
import TranslateComponent from '@/shared/utils/googleTranslate'
import {
  AiOutlineFolderAdd,
  AiOutlinePlus,
  AiOutlineUser,
} from 'react-icons/ai'
import avatar1 from '@/assets/img/images.png'
import AddChatToFolder from './AddChatToFolder'
import { IFolder } from '@/shared/model/folder.model'
import axios from '../../../../shared/config/axios-interceptor'
import { ToastSuccess } from '@/components/shared/toast/Toast'
import * as Yup from 'yup';

interface IProps {
  setRenderAction: any
  renderAction: any
  idFolder: any
  setIdFolder: any
}
const FormChatFolder = ({
  setRenderAction,
  renderAction,
  idFolder,
  setIdFolder,
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication)

  const { initialState } = useSelector((state: RootState) => state.usersReducer)
  const { updateEntitySuccess } = initialState
  const { navigate, params } = useRouter()
  const [removeId, setRemoveId] = useState<any>([])
  const [listRoom, setListRoom] = useState<any>([])

  const [visibleSelectChat, setVisibleSelectChat] = useState(false)

  const initialValues: IFolder = {
    name: '',
    id: 0,
    userIds: [],
  }

  useEffect(() => {
    if (idFolder) {
      setListRoom(idFolder?.rooms)
    }
  }, [idFolder])

  useEffect(() => {
    dispatch(getProfile())
    dispatch(resetAll())
  }, [updateEntitySuccess])


  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Không được để trống')
  });

  if (!user) return <></>

  return (
    <Formik
      enableReinitialize
      initialValues={idFolder || initialValues}
      validationSchema={validationSchema}
      onSubmit={async (value, { resetForm }) => {
        value.userIds = listRoom?.map((item: any) => item.id)
        try {
          if (idFolder) {
            const a = await axios.put('/room/folder', value)
            if (a) {
              setRenderAction(5)
            }
          } else {
            const a = await axios.post('/room/folder', value)
            if (a) {
              setRenderAction(5)
            }
          }
        } catch (error) {
          console.log(error)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit,
        resetForm,
        handleBlur,
      }) => (
        <CForm
          onSubmit={handleSubmit}
          className="form-add-group custom-form-edit-profile"
        >
          <div className={'px-3 my-3'}>
            <div className="text-center">
              <AiOutlineFolderAdd
                size={90}
                color="#fff"
                className="mb-3"
                style={{ opacity: '0.7' }}
              />
              <div style={{ opacity: '0.5' }}>
                Choose chats or types of chats that will appear in this folder.
              </div>
            </div>

            <CRow className={'mb-3 mt-4 px-3'}>
              <CCol xs={12}>
                <div className="box-custom-form-login">
                  <CFormLabel
                    htmlFor="name"
                    style={{ background: '#1F2020', color: '#fff' }}
                  >
                    Folder name
                  </CFormLabel>
                  <CFormInput
                    style={{ background: 'transparent', color: '#fff' }}
                    value={values.name}
                    onChange={handleChange}
                    type="text"
                    id="name"
                    name="name"
                    autoComplete="none"
                    onBlur={handleBlur}
                  />
                </div>
              </CCol>
            </CRow>

            <div>
              <h6 className="px-3">Included Chats</h6>

              <div
                onClick={() => setVisibleSelectChat(true)}
                className="d-flex align-items-center px-3 gap-4 cursor-pointer add-chat-folder"
              >
                <div className="mb-1 text-center" style={{ width: 30 }}>
                  <AiOutlinePlus
                    color="rgb(135, 116, 225)"
                    fill="rgb(135, 116, 225)"
                    size={20}
                  />
                </div>
                <div style={{ fontSize: 14, color: 'rgb(135, 116, 225)' }}>
                  Add Chats
                </div>
              </div>

              <div className="list-chat-in-folders px-3">
                {listRoom
                  ?.filter((obj: any) => !removeId.includes(obj.id))
                  .map((item: any) => (
                    <div className="d-flex justify-content-between align-items-center item-list">
                      <div className="d-flex align-items-center gap-3 my-2">
                        <CAvatar style={{ width: 30 }} src={avatar1} />
                        <div style={{ fontSize: 14 }}>{item?.name}</div>
                      </div>
                      <div
                        onClick={() => {
                          setListRoom(listRoom.filter((obj: any) => obj.id !== item?.id))
                        }}
                        style={{
                          fontSize: 12,
                          opacity: 0.9,
                          cursor: 'pointer',
                          color: '#e53935',
                        }}
                      >
                        Xóa
                      </div>
                    </div>
                  ))}
              </div>

              {/* 
              <div className="list-chat-in-folders px-3">
                {idFolder?.rooms
                  ? idFolder?.rooms?.filter((obj: any) => !removeId.includes(obj.id)).map((item: any) => (
                      <div className="d-flex justify-content-between align-items-center item-list">
                        <div className="d-flex align-items-center gap-3 my-2">
                          <CAvatar style={{ width: 30 }} src={avatar1} />
                          <div style={{ fontSize: 14 }}>{item?.name}</div>
                        </div>
                        <div onClick={() => {
                            const a = [...removeId, item?.id]
                            setRemoveId(a)
                            setFieldValue('userIds', idFolder?.rooms?.filter((obj: any) => !a.includes(obj.id)).map((obj: any) => obj.id)) 
                        }}
                          style={{
                            fontSize: 12,
                            opacity: 0.9,
                            cursor: 'pointer',
                            color: '#e53935',
                          }}
                        >
                          Xóa
                        </div>
                      </div>
                    ))
                  : ''}

              </div> */}
            </div>

            <CButton
              type="submit"
              className="w-100 mt-4"
              style={{ background: 'rgb(51,144,236)', boxShadow: 'none' }}
            >
              <TranslateComponent stringText={'Lưu lại'} />
            </CButton>
          </div>

          <AddChatToFolder
            setVisibleSelectChat={setVisibleSelectChat}
            setFieldValue={setFieldValue}
            values={values}
            visibleSelectChat={visibleSelectChat}
            setListRoom={setListRoom}
            listRoom={listRoom}
          />
        </CForm>
      )}
    </Formik>
  )
}

export default FormChatFolder

import SModal from "@/components/shared/Modal/SModal";
import TranslateComponent from "@/shared/utils/googleTranslate";
import { CForm, CFormInput, CFormLabel, CModalBody, CModalFooter, CModalHeader } from "@coreui/react-pro";
import axios from '../../../../shared/config/axios-interceptor';
import { useState } from "react";
import { Toast } from "react-toastify/dist/components";
import { ToastError, ToastErrorBottom } from "@/components/shared/toast/Toast";
import { useRouter } from "@/shared/utils/hooks/useRouter";

interface IProps {
    chatRooms: any
    setShowModalPass: any
}

const CheckPasswordRoom = ({ chatRooms, setShowModalPass }: IProps) => {
    const [password, setPasswod] = useState('')
    const { navigate } = useRouter()

    const checkPassword = async () => {
        try {
            const check = await axios.post('/room/check-password', {
                password,
                roomId: chatRooms?.id
            })
            if (!check.data) {
                ToastErrorBottom('Sai mật khẩu')
                return
            }
            navigate(`/${chatRooms?.id}`)
            setShowModalPass(null)
        } catch (error) {
            ToastErrorBottom('Sai mật khẩu')
            return
        }
    }

    const onKeyUp = (e: any) => {
        if(e.keyCode === 13){
            checkPassword()
        }
    }

    return <>
        <SModal visible={true} onClose={() => setShowModalPass(null)}>
            <CModalHeader className="border-0 pb-1">
                <h6>
                    <TranslateComponent stringText="Mật khẩu" />
                </h6>
            </CModalHeader>

            <CModalBody>
                <CFormInput onKeyDown={onKeyUp} autoComplete="off" type="password" onChange={(e) => setPasswod(e.target.value)} style={{ background: "transparent", color: "#fff" }} />
                
                <div className="d-flex justify-content-end gap-4 mt-3">
                    <div
                        onClick={() => setShowModalPass(null)}
                        className="cursor-pointer"
                        style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                        
                    >
                        <TranslateComponent stringText="Cancel" />
                    </div>
                    <div
                        className="cursor-pointer"
                        style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                    >
                        <button onClick={checkPassword}
                            className="border-0 bg-transparent "
                            style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                        >
                            <TranslateComponent stringText="Xác nhận" />
                        </button>
                    </div>
                </div>
            </CModalBody>
        </SModal>
    </>
}

export default CheckPasswordRoom;
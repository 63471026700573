import { useDispatch, useSelector } from 'react-redux'
import { chatSelectors, resetAll } from '../chat/chat.reducer'
import { CAvatar, CButton, CFormSwitch } from '@coreui/react-pro'
import avatar1 from '@/assets/img/images.png'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { AppDispatch } from '@/store'
import axios from '../../../../shared/config/axios-interceptor'
import { RootState } from '@/reducers'
import { handleChatAvatar, handleChatRoomName } from '../chat/Chat'
import dayjs from 'dayjs'
import {
  resetEntity,
  userSelectors,
} from '../../UserManagement/usersManagement.reducer'
import { socket } from '@/shared/config/socket'
import {
  AiOutlineBell,
  AiOutlineEdit,
  AiOutlineFolderAdd,
  AiOutlineNotification,
  AiOutlineSetting,
  AiOutlineTeam,
  AiOutlineUser,
  AiOutlineUserAdd,
} from 'react-icons/ai'
import { FiUsers } from 'react-icons/fi'
import {
  logout,
  showAddContact,
  showCreateGroup,
} from '../../auth/auth.reducer'
import EditProfile from './EditProfile'
import { BsBookmark, BsGlobe2, BsTelephone } from 'react-icons/bs'
import TranslateComponent from '@/shared/utils/googleTranslate'
import { getTruncateTxt } from '@/shared/utils/ultils'
import { MessageType } from '../chat/Message/Message'
import CheckPasswordRoom from './CheckPasswordRoom'
import { useEffect, useState } from 'react'
import { updateEntity } from '../../UserManagement/usersManagement.api'
import { getProfile } from '../../auth/auth.api'
import ChatFolder from './ChatFolder'
import FormChatFolder from './FormChatFolder'
import { IFolder } from '@/shared/model/folder.model'
import { getEntities } from '../chat/chat.api'
// import { googleTranslate } from '@/shared/utils/googleTranslate'

interface Iprops {
  renderAction: any
  setRenderAction: any
  listChatRef: any
}
const ContentLeft = (props: Iprops) => {
  const { renderAction, setRenderAction, listChatRef } = props
  const { navigate, params } = useRouter()
  const { roomId } = params
  const dispatch = useDispatch<AppDispatch>()
  const { initialState } = useSelector(
    (state: RootState) => state.chatReducerFe,
  )
  const { filterState, totalPages, listContact } = initialState
  const { user } = useSelector((state: RootState) => state.authentication)

  const { initialState: int } = useSelector(
    (state: RootState) => state.usersReducer,
  )

  const { updateEntitySuccess: upd } = int

  const [showModalPass, setShowModalPass] = useState()
  const [idFolder, setIdFolder] = useState<any>()
  const [folders, setFolders] = useState<IFolder[]>([])
  const [tabFolder, setTabFolder] = useState(0)

  const chatRooms = useSelector(chatSelectors.selectAll)

  const dataUserContact = listContact.map((item) => {
    if (item.isGroup === 1) {
      return {
        id: item.id,
        name: item.name,
        avatar: item.avatar,
        isGroup: 1,
        number: item.number_of_members,
      }
    }
    const users = item?.users?.filter((i) => i.id !== user?.id)[0]
    return {
      id: item.id,
      name: users?.fullName ?? users?.username,
      avatar: users?.avatar,
      isGroup: 0,
      number: 0,
    }
  })

  const onLogout = () => {
    dispatch(logout())
    dispatch(resetAll())
  }

  const getRoom = async (userId: number, username: string) => {
    try {
      const response = await axios.get('/room/get-room-user-custom/' + userId)
      if (!response?.data?.length) {
        socket.emit('new_room', { users: [user?.id, userId], name: username })

        socket.on(`user_${user?.id}_new_room`, (res: any) => {
          navigate(`/${res.room?.id}`)
          setRenderAction(0)
          socket.off(`user_${user?.id}_new_room`)
        })
      } else {
        navigate(`/${response?.data[0]?.room_id}`)
        setRenderAction(0)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getFolders = async () => {
    try {
      const response = await axios.get('/room/get-folder-by-user  ')
      if (response.status === 200) {
        setFolders(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getFolders()
  }, [renderAction])

  const seenMessage = async (roomId: number) => {
    try {
      const a = await axios.post(`/user/seen-notify`, {
        roomId,
        userId: Number(user?.id),
      })
      if (a) {
        dispatch(getEntities(filterState))
      }
    } catch (err) {}
  }

  const checkSendMessenge = (userId: string, username: string) => {
    getRoom(+userId, username)

    // get-room-user-custom/:id
    // return userItems.filter((i) => i.id === item.fromUser.id).length > 0
  }

  const handleUpdateNotify = async () => {
    if (user) {
      dispatch(
        updateEntity({
          ...user,
          isNotify: user?.isNotify == 'YES' ? 'NO' : 'YES',
        }),
      )
    }
  }

  useEffect(() => {
    if (upd) {
      dispatch(getProfile())
      dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upd])

  const detailMessage = (item: any) => {
    if (item.isGroup == 1 && item.passwordGroup != null) {
      setShowModalPass(item)
      return
    }

    if (item.isGroup == 0) {
      if (
        Number(user?.id) === Number(item.createdBy) &&
        item.passwordUser1 != null
      ) {
        setShowModalPass(item)
        return
      }

      if (
        Number(user?.id) !== Number(item.createdBy) &&
        item.passwordUser2 != null
      ) {
        setShowModalPass(item)
        return
      }
    }

    navigate(`/${item.id}`)
  }

  // Mặc định
  if (renderAction === 0) {
    return (
      <div>
        <div
          className="d-flex gap-2 px-3 tabs-header-cotent mt-2"
          style={{ overflowX: 'auto' }}
        >
          <div
            onClick={() => setTabFolder(0)}
            className={tabFolder === 0 ? 'item p-2 active' : 'item p-2'}
          >
            All
          </div>
          {folders.map((item) => {
            return (
              <div
                key={item.id}
                onClick={() => setTabFolder(item.id)}
                className={
                  tabFolder === item.id ? 'item p-2 active' : 'item p-2'
                }
                style={{ whiteSpace: 'nowrap' }}
              >
                {item.name}
              </div>
            )
          })}
        </div>
        <div className={'list-chat-fe h-auto'} ref={listChatRef}>
          {tabFolder === 0 && (
            <>
              {chatRooms.map((room) => (
                <div
                  key={`chat-${room.id}`}
                  className={`chat-room ${
                    Number(roomId) === +room.id ? 'active' : ''
                  }`}
                  onClick={() => {
                    detailMessage(room)
                    seenMessage(+room.id)
                  }}
                >
                  <div className="d-flex align-items-center me-md-12">
                    {handleChatAvatar(room, user)}
                  </div>
                  <div className="d-flex flex-column justify-content-center w-100 ms-12">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <span className="chat-room-name  text-truncate-custom text-white">
                        {handleChatRoomName(room, user)}
                      </span>
                      <span className="text-xs  " style={{ color: '#9aa4b2' }}>
                        {dayjs(
                          room.latestMessage?.timestamp || room.createdDate,
                        )
                          .fromNow()
                          .replaceAll('một', '1')}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between">
                      <p className="message m-0 text-truncate-custom">
                        {room.latestMessage?.type == MessageType.TEXT ||
                        room.latestMessage?.type == MessageType.LINK ? (
                          <TranslateComponent
                            stringText={getTruncateTxt(
                              room.latestMessage?.content,
                              35,
                            )}
                          />
                        ) : (
                          <TranslateComponent stringText="Đang cập nhật..." />
                        )}
                      </p>

                      {room?.userRoomMesssage?.[0] &&
                      room?.userRoomMesssage?.[0].readNumber ? (
                        <span
                          className="d-flex justify-content-center align-items-center rounded-circle"
                          style={{
                            color: '#9aa4b2',
                            background: 'rgb(118, 106, 200)',
                            width: 22,
                            height: 22,
                            fontSize: 12,
                          }}
                        >
                          {room?.userRoomMesssage?.[0].readNumber}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              {chatRooms.length === 0 ? (
                <div className="text-center mt-3">
                  <TranslateComponent stringText="Không có cuộc trò chuyện" />
                </div>
              ) : null}

              {showModalPass && (
                <CheckPasswordRoom
                  chatRooms={showModalPass}
                  setShowModalPass={setShowModalPass}
                />
              )}
            </>
          )}

          {folders.map(
            (folder) =>
              tabFolder === folder.id && (
                <>
                  {folder?.rooms?.map((room) => (
                    <div
                      key={`chat-${room.id}`}
                      className={`chat-room ${
                        Number(roomId) === +room.id ? 'active' : ''
                      }`}
                      onClick={() => detailMessage(room)}
                    >
                      <div className="d-flex align-items-center me-md-12">
                        {handleChatAvatar(room, user)}
                      </div>
                      <div className="d-flex flex-column justify-content-center w-100 ms-12">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <span className="chat-room-name  text-truncate-custom text-white">
                            {handleChatRoomName(room, user)}
                          </span>
                          <span
                            className="text-xs  "
                            style={{ color: '#9aa4b2' }}
                          >
                            {dayjs(
                              room.latestMessage?.timestamp || room.createdDate,
                            )
                              .fromNow()
                              .replaceAll('một', '1')}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between">
                          <p className="message m-0 text-truncate-custom">
                            {room.latestMessage?.type == MessageType.TEXT ||
                            room.latestMessage?.type == MessageType.LINK ? (
                              <TranslateComponent
                                stringText={getTruncateTxt(
                                  room.latestMessage?.content,
                                  35,
                                )}
                              />
                            ) : (
                              <TranslateComponent stringText="Đang cập nhật..." />
                            )}
                          </p>

                          {room?.userRoomMesssage?.[0] &&
                          room?.userRoomMesssage?.[0].readNumber ? (
                            <span
                              className="d-flex justify-content-center align-items-center rounded-circle"
                              style={{
                                color: '#9aa4b2',
                                background: 'rgb(118, 106, 200)',
                                width: 22,
                                height: 22,
                                fontSize: 12,
                              }}
                            >
                              {room?.userRoomMesssage?.[0].readNumber}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}

                  {chatRooms.length === 0 ? (
                    <div className="text-center mt-3">
                      <TranslateComponent stringText="Không có cuộc trò chuyện" />
                    </div>
                  ) : null}

                  {/* {showModalPass && (
                  <CheckPasswordRoom
                    chatRooms={showModalPass}
                    setShowModalPass={setShowModalPass}
                  />
                )} */}
                </>
              ),
          )}
        </div>
      </div>
    )
  }

  // Màn search
  if (renderAction === 1) {
    return (
      <div className="search">
        <div className="list-search">
          <div className="list-chat-fe d-block" ref={listChatRef}>
            {dataUserContact.map((room) => (
              <div
                key={`chat-${room.id}`}
                className={`chat-room`}
                onClick={() => {
                  navigate(`/${room.id}`)
                  setRenderAction(0)
                }}
              >
                <div className="d-flex align-items-center me-md-12">
                  <CAvatar src={room.avatar || avatar1} className="avatar-40" />
                </div>
                <div className="d-block w-100 ms-12">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <span className="chat-room-name text-white">
                      {room.name}
                    </span>
                  </div>
                  <p className="message m-0">
                    {room.isGroup == 1 ? (
                      <>
                        {room.number}{' '}
                        <TranslateComponent stringText="Members" />
                      </>
                    ) : (
                      <TranslateComponent stringText="Online" />
                    )}
                  </p>
                </div>
              </div>
            ))}

            {dataUserContact.length === 0 ? (
              <div className="text-center mt-3">
                <TranslateComponent stringText="Không có người dùng" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  // Màn settings
  if (renderAction === 2) {
    return (
      <>
        <div className="d-flex flex-column">
          <div className="box-top p-3 border-bottom">
            <CAvatar
              src={user?.avatar || avatar1}
              style={{ width: 55, height: 55 }}
            />
            <div className="fw-bold mt-2 d-flex align-items-center gap-2">
              {user?.fullName ?? user?.username}{' '}
              <div
                className="cursor-pointer mb-1"
                onClick={() => setRenderAction(3)}
              >
                <AiOutlineEdit />
              </div>
            </div>
          </div>

          <div
            style={{ height: 'calc(100dvh - 180px)' }}
            className="d-flex flex-column justify-content-between"
          >
            <div className="list list-item-action mt-2">
              <div
                onClick={() => {
                  dispatch(showCreateGroup())
                  setRenderAction(0)
                }}
                className="item-list px-3 cursor-pointer d-flex align-items-center gap-3"
              >
                <FiUsers color="#fff" size={22} />
                <div className="fw-bold" style={{ fontSize: 14 }}>
                  <TranslateComponent stringText="New Group" />
                </div>
              </div>
              <div
                onClick={() => {
                  dispatch(showCreateGroup())
                  setRenderAction(0)
                }}
                className="item-list px-3 cursor-pointer d-flex align-items-center gap-3"
              >
                <AiOutlineNotification color="#fff" size={22} />
                <div className="fw-bold" style={{ fontSize: 14 }}>
                  <TranslateComponent stringText="New Chanel" />
                </div>
              </div>

              <div
                onClick={() => {
                  dispatch(showAddContact())
                  setRenderAction(0)
                }}
                className="item-list px-3 cursor-pointer d-flex align-items-center gap-3"
              >
                <AiOutlineUserAdd color="#fff" size={22} />
                <div className="fw-bold" style={{ fontSize: 14 }}>
                  <TranslateComponent stringText="Contact" />
                </div>
              </div>

              <div className="item-list px-3 cursor-pointer d-flex align-items-center gap-3">
                <BsTelephone color="#fff" size={22} />
                <div className="fw-bold" style={{ fontSize: 14 }}>
                  <TranslateComponent stringText="Calls" />
                </div>
              </div>

              <div className="item-list px-3 cursor-pointer d-flex align-items-center gap-3">
                <BsBookmark color="#fff" size={22} />
                <div className="fw-bold" style={{ fontSize: 14 }}>
                  <TranslateComponent stringText="Saved Messages" />
                </div>
              </div>

              <div
                onClick={() => setRenderAction(3)}
                className="item-list px-3 cursor-pointer d-flex align-items-center gap-3"
              >
                <AiOutlineSetting color="#fff" size={22} />
                <div className="fw-bold" style={{ fontSize: 14 }}>
                  <TranslateComponent stringText="Settings" />
                </div>
              </div>

              <div
                className="item-list px-3 cursor-pointer d-flex align-items-center gap-3"
                onClick={() => setRenderAction(3)}
              >
                <AiOutlineUser color="#fff" size={22} />
                <div className="fw-bold" style={{ fontSize: 14 }}>
                  <TranslateComponent stringText="My Account" />
                </div>
              </div>

              <div
                className="item-list px-3 cursor-pointer d-flex align-items-center gap-3"
                onClick={() => setRenderAction(5)}
              >
                <AiOutlineFolderAdd color="#fff" size={22} />
                <div className="fw-bold" style={{ fontSize: 14 }}>
                  <TranslateComponent stringText="Folder" />
                </div>
              </div>

              <div
                className="item-list px-3 cursor-pointer d-flex align-items-center gap-3"
                onClick={() => setRenderAction(4)}
              >
                <BsGlobe2 color="#fff" size={22} />
                <div className="fw-bold" style={{ fontSize: 14 }}>
                  <TranslateComponent stringText="Language" />
                </div>
              </div>

              <div className="item-list px-3 cursor-pointer d-flex align-items-center gap-3 justify-content-between">
                <div className="d-flex gap-3 align-items-center">
                  <AiOutlineBell color="#fff" size={22} />
                  <div className="fw-bold" style={{ fontSize: 14 }}>
                    <TranslateComponent stringText="Notify" />
                  </div>
                </div>

                <div>
                  <CFormSwitch
                    checked={user?.isNotify == 'YES'}
                    className="me-2"
                    onChange={handleUpdateNotify}
                    // disabled={loading}
                  />
                </div>
              </div>
            </div>
            <div className="p-3">
              <CButton
                className="w-100"
                style={{ background: 'rgb(51,144,236)' }}
                onClick={onLogout}
              >
                <TranslateComponent stringText="Logout" />
              </CButton>
            </div>
          </div>
        </div>
      </>
    )
  }

  // Màn edit profile
  if (renderAction === 3) {
    return (
      <>
        <div className="d-flex flex-column">
          <EditProfile />
        </div>
      </>
    )
  }

  // Màn chọn ngôn ngữ
  if (renderAction === 4) {
    const data = [
      { code: 'vi', name: 'Tiếng Việt' },
      { code: 'en', name: 'English' },
      { code: 'ja', name: '日本語' },
      { code: 'ko', name: '한국어' },
      { code: 'zh-CN', name: '简体中文' },
      { code: 'de', name: 'Deutsch' },
      { code: 'hi', name: 'हिन्दी' }, // Hindi (India)
      { code: 'id', name: 'Bahasa Indonesia' }, // Indonesian
      { code: 'tl', name: 'Filipino' }, // Filipino (Tagalog)
      { code: 'fr', name: 'Français' }, // French
      { code: 'es', name: 'Español' }, // Spanish
      { code: 'it', name: 'Italiano' }, // Italian
      { code: 'ru', name: 'Русский' }, // Russian
      { code: 'pt', name: 'Português' }, // Portuguese
      { code: 'ar', name: 'العربية' }, // Arabic
      { code: 'th', name: 'ไทย' }, // Thai
      { code: 'ms', name: 'Bahasa Melayu' }, // Malay
      { code: 'bn', name: 'বাংলা' }, // Bengali
      { code: 'ur', name: 'اردو' }, // Urdu
      { code: 'fa', name: 'فارسی' }, // Persian
    ]
    const lang = localStorage.getItem('lang') || 'en'
    return (
      <>
        <div className="d-flex flex-column selecte-language">
          {data.map((item, index) => (
            <div
              key={index}
              className={
                lang === item.code
                  ? 'item-list px-3 cursor-pointer d-flex align-items-center gap-3 active'
                  : 'item-list px-3 cursor-pointer d-flex align-items-center gap-3'
              }
              onClick={() => {
                localStorage.setItem('lang', item.code)
                window.location.reload()
              }}
            >
              <BsGlobe2 color="#707579" size={22} />
              <div className="fw-bold" style={{ fontSize: 14 }}>
                {item.name}
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }

  if (renderAction === 5) {
    return (
      <>
        <div className="d-flex flex-column">
          <ChatFolder
            setRenderAction={setRenderAction}
            renderAction={renderAction}
            setIdFolder={setIdFolder}
            idFolder={idFolder}
          />
        </div>
      </>
    )
  }

  if (renderAction === 6) {
    return (
      <>
        <div className="d-flex flex-column">
          <FormChatFolder
            setRenderAction={setRenderAction}
            renderAction={renderAction}
            idFolder={idFolder}
            setIdFolder={setIdFolder}
          />
        </div>
      </>
    )
  }

  return <></>
}

export default ContentLeft

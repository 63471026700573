import { RootState } from '@/reducers';
import { socket } from '@/shared/config/socket';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { AppDispatch } from '../../store';
import TheAside from './TheAside';
import TheContent from './TheContent';
import TheHeader from './TheHeader';
import TheSidebar from './TheSidebar';
import { setBodyWidth } from './reducer';
import axios from '../../shared/config/axios-interceptor'


const TheLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { ref: bodyRef, width: bodyWidth } = useResizeDetector();
  const { user } = useSelector((state: RootState) => state.authentication);
  const audioRef: any = useRef(null)

  const [loadHistory, setLoadHistory] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setBodyWidth(bodyWidth || 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyWidth]);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on(`user_${user?.id}_new_message`, (newMsg: any) => {
      console.log('Bạn có tin nhắn mới: ', newMsg);
    });

    return () => {
      console.log('disconnected')
      socket.off(`user_${user?.id}_new_message`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div>
      <TheAside />
      <TheSidebar />

      <div className="wrapper d-flex flex-column min-vh-100 bg-light" ref={bodyRef}>
        <TheHeader />

        <div className="body flex-grow-1">
          <TheContent />
        </div>
        {/* <TheFooter /> */}
      </div>
    </div>
  );
};
export default TheLayout;

import { CAvatar } from '@coreui/react-pro'
import avatar1 from '@/assets/img/images.png'
import { FiUsers } from 'react-icons/fi'
import { AiOutlineUserAdd } from 'react-icons/ai'
import axios from '../../../../shared/config/axios-interceptor'
import { useEffect, useState } from 'react'
import { IUser } from '@/shared/model/user.model'

interface Iprops {
  chatRoom: any
}
const GroupInfo = ({ chatRoom }: Iprops) => {
  const [users, setUsers] = useState<IUser[]>([])
  const getUserByRome = async () => {
    try {
      const response = await axios.get('/room/detail/' + chatRoom.id)
      setUsers(response?.data?.users)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getUserByRome()
  }, [])
  return (
    <>
      <div style={{ background: '#3C3C3C' }} className="py-1 my-3"></div>

      <div className="d-flex gap-3 align-items-center justify-content-between px-3 mt-4">
        <div className="d-flex align-items-center">
          <div style={{ width: 80 }} className='ps-2'>
            <FiUsers color="#707579" size={30} />
          </div>
          <div className="text-uppercase fw-bold">
            {chatRoom.number_of_members + ' Members'}
          </div>
        </div>
        <div className="cursor-pointer">
          <AiOutlineUserAdd size={30} color="#707579" />
        </div>
      </div>

      <div>
        {users.map((value, index) => (
          <div className={`chat-room d-flex align-items-center px-3 mt-4`}>
            <div className="d-flex align-items-center me-md-12">
              <CAvatar
                src={value.avatar || avatar1}
                className="avatar-54"
                style={{ width: 54, height: 54 }}
              />
            </div>
            <div className="d-none d-md-flex flex-column justify-content-center w-100 ms-12">
              <div className="d-flex justify-content-between align-items-center mb-1">
                <span className="chat-room-name  text-truncate-custom fw-bold">
                  {value.fullName || value.username}
                </span>
                <span className="text-xs  " style={{ color: '#9aa4b2' }}></span>
              </div>
              <p className="message m-0 text-truncate-custom">Online</p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default GroupInfo
